import { FC, useEffect, useRef, useState } from 'react';

interface AutoScalingTextProps {
    text: string;
    maxFontSize: number;
    maxLines?: number;
    style?: React.CSSProperties;
}

const AutoScalingText: FC<AutoScalingTextProps> = ({
    text,
    maxFontSize,
    maxLines = 1,
    style
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState(maxFontSize);

    useEffect(() => {
        let animationFrame: number;

        function adjustFontSize() {
            if (!containerRef.current) return;
            const parent = containerRef.current.parentElement;
            if (!parent) return;

            // 1) measure the parent's width
            const parentWidth = parent.clientWidth; // could also use offsetWidth

            // 2) reset font size
            let currentSize = maxFontSize;
            containerRef.current.style.fontSize = currentSize + 'px';
            containerRef.current.style.maxWidth = parentWidth + 'px';

            // 3) while content is overflowing horizontally, shrink font
            while (
                containerRef.current.scrollWidth >
                    containerRef.current.clientWidth &&
                currentSize > 1
            ) {
                currentSize--;
                containerRef.current.style.fontSize = currentSize + 'px';
            }

            setFontSize(currentSize);
        }

        // Use requestAnimationFrame to ensure layout is calculated
        function rafAdjust() {
            animationFrame = requestAnimationFrame(adjustFontSize);
        }

        // Adjust immediately + on resize
        rafAdjust();
        window.addEventListener('resize', rafAdjust);

        return () => {
            window.removeEventListener('resize', rafAdjust);
            cancelAnimationFrame(animationFrame);
        };
    }, [text, maxFontSize]);

    return (
        <div
            ref={containerRef}
            className="w-full"
            style={{
                ...style,
                fontSize: `${fontSize}px`,
                whiteSpace: maxLines === 1 ? 'nowrap' : 'normal',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: maxLines,
                WebkitBoxOrient: 'vertical'
            }}
        >
            {text}
        </div>
    );
};

export default AutoScalingText;
