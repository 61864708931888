import { type FC } from 'react';

import TextButton from '@/components/buttons/TextButton';
import Modal from '@/components/Modal';
import { useLabels } from '@/context';

interface PlayUnlockedCategoryModalProps {
    isVisible: boolean;
    onYesPress: () => void;
    onLaterPress?: () => void;
    onClose: () => void;
    buttonYesLoading?: boolean;
}

const PlayUnlockedCategoryModal: FC<PlayUnlockedCategoryModalProps> = ({
    isVisible,
    onYesPress,
    onLaterPress,
    onClose,
    buttonYesLoading = false
}) => {
    const { getLabel } = useLabels();

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline>
            {/* Modal Header */}
            <div className="mb-6 flex items-start justify-between">
                <h2 className="font-bold text-white">
                    {getLabel('category.play-unlocked-category')}
                </h2>
            </div>

            <div className="mb-3 flex flex-col gap-2.5">
                <TextButton
                    onClick={buttonYesLoading ? undefined : onYesPress}
                    text={getLabel('button.yes')}
                    verticalPadding="py-2"
                    isLoading={buttonYesLoading}
                    isDisabled={buttonYesLoading}
                />
                <TextButton
                    className="mt-2"
                    onClick={onLaterPress}
                    text={getLabel('button.later')}
                    verticalPadding="py-2"
                    isDisabled={buttonYesLoading}
                />
            </div>
        </Modal>
    );
};

export default PlayUnlockedCategoryModal;
