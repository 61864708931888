import { FC, Fragment } from 'react';
import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild
} from '@headlessui/react';
import classNames from 'classnames';

import { useVibration } from '@/hooks/useVibration';

interface ModalProps {
    isVisible: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    outline?: boolean;
    modalBackgroundClass?: string;
    overlayOpacityClass?: string;
}

const Modal: FC<ModalProps> = ({
    isVisible,
    onClose,
    children,
    outline,
    modalBackgroundClass,
    overlayOpacityClass
}) => {
    const { vibrateLight } = useVibration();

    const onCloseClick = () => {
        vibrateLight();
        onClose();
    };

    return (
        <Transition appear show={isVisible} as={Fragment}>
            {/* overlay */}
            <Dialog as="div" className="relative z-10" onClose={onCloseClick}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className={classNames(
                            'fixed inset-0 bg-black',
                            overlayOpacityClass
                                ? overlayOpacityClass
                                : 'bg-opacity-75'
                        )}
                    />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={classNames(
                                    'max-h-[90vh] w-full max-w-md transform overflow-auto rounded-2xl p-6 text-left align-middle transition-all',
                                    modalBackgroundClass
                                        ? modalBackgroundClass
                                        : 'bg-black',
                                    {
                                        'rounded-3xl border-2 border-white border-opacity-20 p-5':
                                            outline
                                    }
                                )}
                            >
                                {children}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Modal;
