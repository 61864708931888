import { type FC, ReactNode } from 'react';

import Modal from '@/components/Modal';

interface ChooseTranslationModalProps {
    isVisible: boolean;
    onClose: () => void;
    children: ReactNode;
}

const ChooseTranslationModal: FC<ChooseTranslationModalProps> = ({
    isVisible,
    onClose,
    children
}) => {
    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            modalBackgroundClass="bg-black/95"
            overlayOpacityClass="bg-opacity-50"
        >
            {children}
        </Modal>
    );
};

export default ChooseTranslationModal;
