import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import axios from 'axios';
import axiosRetry, { exponentialDelay, isNetworkError } from 'axios-retry';

import { LeaderboardData } from '@/types/leaderboard';
import {
    CategoryProgressItem,
    LandscapeProgressItem,
    Streak
} from '@/types/progress';
import { LanguageSettings, RemoteSettings } from '@/types/settings';
import { API_URL } from '@/utils/constants';

import {
    claimFriendReferralRewardResponse,
    ClaimStreakRewardResponse,
    GetAllProgressResponse,
    GetGameConfigResponse,
    GetSettingsResponse,
    GetUserResponse,
    PostCoinsResponse,
    PostLandscapesProgressResponse,
    PostLanguageSettingsResponse,
    PostWordsProgressResponse,
    PutGameLevelResponse,
    PutLandscapeProgressResponse,
    PutSelectedLandscapeIdResponse,
    PutSettingsResponse,
    PutXpResponse
} from './types';

const apiClient = axios.create({
    baseURL: API_URL
});

axiosRetry(apiClient, {
    retries: 3,
    retryDelay: exponentialDelay,
    retryCondition: error => {
        // Always retry on network errors.
        if (isNetworkError(error)) return true;

        // Check if we got a response and if it's one of the transient 5xx errors
        if (error.response) {
            const status = error.response.status;
            return [502, 503, 504].includes(status);
        }

        return false;
    }
});

apiClient.interceptors.request.use(
    config => {
        const launchParams = retrieveLaunchParams();

        if (launchParams.initDataRaw) {
            config.headers.Authorization = `tma ${launchParams.initDataRaw}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getUserApi = async (): Promise<GetUserResponse> => {
    const response = await apiClient.get<GetUserResponse>('/user');
    return response.data;
};

export const postUserApi = async (
    referrerId?: number
): Promise<GetUserResponse> => {
    const response = await apiClient.post<GetUserResponse>('/user', {
        referrer: referrerId
    });
    return response.data;
};

const getGameConfigApi = async (): Promise<GetGameConfigResponse> => {
    const response = await apiClient.get<GetGameConfigResponse>('/game-config');
    return response.data;
};

const getUserSettingsApi = async (): Promise<GetSettingsResponse> => {
    const response = await apiClient.get<GetSettingsResponse>(`/user/settings`);
    return response.data;
};

const updateUserSettingsApi = async (
    updatedSettings: Partial<RemoteSettings>
): Promise<PutSettingsResponse> => {
    const response = await apiClient.put<RemoteSettings>(
        `/user/settings`,
        updatedSettings
    );
    return response.data;
};

const updateLanguageSettingsApi = async (
    lang: string,
    languageLevel: string
): Promise<PostLanguageSettingsResponse> => {
    const response = await apiClient.post<LanguageSettings>(
        `/user/${lang}/settings`,
        { languageLevel }
    );
    return response.data;
};

const updateWordsProgressApi = async (
    lang: string,
    categoryProgressItems: CategoryProgressItem[]
): Promise<PostWordsProgressResponse> => {
    const response = await apiClient.post(`/user/${lang}/progress/words`, {
        categoryProgressItems
    });
    return response.data;
};

const updateLandscapesProgressApi = async (
    lang: string,
    landscapeProgressItem: LandscapeProgressItem
): Promise<PostLandscapesProgressResponse> => {
    const response = await apiClient.post(
        `/user/${lang}/progress/landscapes`,
        landscapeProgressItem
    );
    return response.data;
};

const updateCoinsApi = async (coins: number): Promise<PostCoinsResponse> => {
    const response = await apiClient.post(`/user/coins`, {
        coins
    });
    return response.data;
};

const updateGameLevelApi = async (
    lang: string,
    level: number
): Promise<PutGameLevelResponse> => {
    const response = await apiClient.put(`/user/${lang}/progress`, {
        gameLevel: level
    });
    return response.data;
};

const updateXpApi = async (
    lang: string,
    xp: number
): Promise<PutXpResponse> => {
    const response = await apiClient.put(`/user/${lang}/progress`, { xp });
    return response.data;
};

const updateSelectedLandscapeIdApi = async (
    lang: string,
    landscapeId: string
): Promise<PutSelectedLandscapeIdResponse> => {
    const response = await apiClient.put(`/user/${lang}/progress`, {
        selectedLandscapeId: landscapeId
    });
    return response.data;
};

const updateLandscapeProgressApi = async (
    lang: string,
    landscapeProgress: number
): Promise<PutLandscapeProgressResponse> => {
    const response = await apiClient.put(`/user/${lang}/progress`, {
        landscapeProgress
    });
    return response.data;
};

const generateInvoiceApi = async (code: string): Promise<string> => {
    const response = await apiClient.post('/user/generateInvoice', {
        code
    });

    return response.data.invoiceLink;
};

const getLeaderboardApi = async (
    lang: string,
    top: number = 10,
    window: number = 5
): Promise<LeaderboardData> => {
    const response = await apiClient.get<LeaderboardData>(
        `/user/${lang}/leaderboard`,
        {
            params: {
                top,
                window
            }
        }
    );
    return response.data;
};

const getAllProgressApi = async (
    lang: string
): Promise<GetAllProgressResponse> => {
    const response = await apiClient.get<GetAllProgressResponse>(
        `/user/${lang}/progress/all`
    );
    return response.data;
};

const getStreakApi = async (): Promise<Streak> => {
    const timestamp = new Date().toISOString();
    const response = await apiClient.get<Streak>('/user/streak', {
        params: { timestamp }
    });
    return response.data;
};

const updateStreakApi = async (): Promise<Streak> => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const response = await apiClient.post<Streak>('/user/streak/update', {
        timeZone
    });
    return response.data;
};

const clearUserDataApi = async (): Promise<void> => {
    await apiClient.post('/user/clear-storage');
};

const claimStreakRewardApi = async (): Promise<ClaimStreakRewardResponse> => {
    const response = await apiClient.post('/user/streak/claim-reward');
    return response.data;
};

const claimFriendReferralRewardApi = async (
    friendId: string
): Promise<claimFriendReferralRewardResponse> => {
    const response = await apiClient.post('/user/friendship/claim-reward', {
        invitee: friendId
    });
    return response.data;
};

const updateUserTimezoneApi = async (
    timezone: string
): Promise<GetUserResponse> => {
    const response = await apiClient.post<GetUserResponse>('/user/timezone', {
        timezone
    });
    return response.data;
};

export {
    claimFriendReferralRewardApi,
    claimStreakRewardApi,
    clearUserDataApi,
    generateInvoiceApi,
    getAllProgressApi,
    getGameConfigApi,
    getLeaderboardApi,
    getStreakApi,
    getUserSettingsApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateLanguageSettingsApi,
    updateSelectedLandscapeIdApi,
    updateStreakApi,
    updateUserSettingsApi,
    updateUserTimezoneApi,
    updateWordsProgressApi,
    updateXpApi
};
