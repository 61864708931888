import { type FC } from 'react';

import TextButton from '@/components/buttons/TextButton';
import Modal from '@/components/Modal';
import { useLabels } from '@/context';

import coinIcon from '../../../../assets/images/icons/coin.png';

interface UnlockCategoryModalProps {
    isVisible: boolean;
    onButton1Press: () => void;
    onButton2Press?: () => void;
    onClose: () => void;
    button1Loading?: boolean;
    unlockCategoryPrice: number;
}

const UnlockCategoryModal: FC<UnlockCategoryModalProps> = ({
    isVisible,
    onButton1Press,
    onButton2Press,
    onClose,
    button1Loading = false,
    unlockCategoryPrice
}) => {
    const { getLabel } = useLabels();

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline>
            <div className="mb-6 flex items-start justify-between">
                <h2 className="font-bold text-white">
                    {getLabel('category.would-you-like-to-unlock')}
                </h2>
            </div>

            {/* Display Current Coins and Unlock Price */}
            <div className="mb-8 flex items-center rounded-xl bg-black/30">
                <span className="mr-2 text-white">
                    {getLabel('common.price')}:
                </span>
                <div className="flex items-center">
                    <span className="mr-1 font-semibold text-white">
                        {unlockCategoryPrice}
                    </span>
                    <img src={coinIcon} alt="Coins" className="h-5 w-5" />
                </div>
            </div>

            {/* Action Buttons */}
            <div className="mb-3 flex flex-col gap-2.5">
                <TextButton
                    onClick={button1Loading ? undefined : onButton1Press}
                    text={getLabel('button.yes')}
                    verticalPadding="py-2"
                    isLoading={button1Loading}
                    isDisabled={button1Loading}
                />
                <TextButton
                    className="mt-2"
                    onClick={onButton2Press}
                    text={getLabel('button.later')}
                    verticalPadding="py-2"
                    isDisabled={button1Loading}
                />
            </div>
        </Modal>
    );
};

export default UnlockCategoryModal;
