import {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';
import { cloudStorage } from '@telegram-apps/sdk-react';

import {
    getUserSettingsApi,
    updateLanguageSettingsApi,
    updateUserSettingsApi
} from '@/api/client';
import { audioManager } from '@/audio/AudioManager';
import emitter from '@/events/emitter';
import {
    ClientSettings,
    RemoteSettings,
    UserAndLanguageSettings
} from '@/types/settings';

const DEFAULT_CLIENT_SETTINGS: ClientSettings = {
    isAudioHintTooltipNeeded: true,
    isMagicWandTooltipNeeded: true,
    isReviewModeTooltipNeeded: true,
    isLeaderboardTooltipNeeded: true,
    isReviewWithoutSpacedRepViewed: false,
    isFriendsModalViewed: false,
    isShowDevControls: false,
    isSkipWordsIntro: false,
    isVibrationOn: true,
    isChooseTranslationHintViewed: false,
    timezone: '',
    sfxVolume: 50,
    wordVolume: 50
};

interface SettingsContextType {
    loadSettings: (onLoadingFinished: () => void) => Promise<void>;
    settings: UserAndLanguageSettings;
    setUILanguage: (languageCode: string) => Promise<void>;
    setStudyLanguage: (languageCode: string) => Promise<void>;
    addNewStudyLanguage: (languageCode: string) => Promise<void>;
    setLanguageLevel: (studyLanguage: string, level: string) => Promise<void>;
    setSfxVolume: (volume: number) => Promise<void>;
    setWordVolume: (volume: number) => Promise<void>;
    setIsVibrationOn: (isOn: boolean) => Promise<void>;
    updateDevControlsClickCounter: () => void;
    updateIsMagicWandTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsAudioHintTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsReviewModeTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateIsLeaderboardTooltipNeeded: (isNeeded: boolean) => Promise<void>;
    updateTimezone: (timezone: string) => Promise<void>;
    setIsReviewWithoutSpacedRepViewed: () => Promise<void>;
    setIsFriendsModalViewed: () => Promise<void>;
    setIsChooseTranslationHintViewed: () => Promise<void>;
    setIsSkipWordsIntro: (isSkip: boolean) => Promise<void>;
}

interface SettingsProviderProps {
    children: ReactNode;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
    undefined
);

export const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
    const [settings, setSettings] = useState<UserAndLanguageSettings>({});
    const [devControlsClickCounter, setDevControlsClickCounter] = useState(0);

    // Helper function to get client settings from cloudStorage
    const getClientSettings = useCallback(async (): Promise<ClientSettings> => {
        try {
            let clientSettingsString: string | null;

            if (import.meta.env.DEV) {
                clientSettingsString = localStorage.getItem('clientSettings');
            } else {
                clientSettingsString =
                    await cloudStorage.getItem('clientSettings');
            }

            const clientSettings: ClientSettings = clientSettingsString
                ? JSON.parse(clientSettingsString)
                : {};

            // Merge with default settings
            const mergedSettings = {
                ...DEFAULT_CLIENT_SETTINGS,
                ...clientSettings
            };

            return mergedSettings;
        } catch (error) {
            console.error('Failed to load client settings', error);
            // Return default settings if there's an error
            return { ...DEFAULT_CLIENT_SETTINGS };
        }
    }, []);

    // Helper function to save client settings to cloudStorage
    const saveClientSettings = useCallback(
        async (clientSettings: ClientSettings) => {
            const clientSettingsString = JSON.stringify(clientSettings);

            if (import.meta.env.DEV) {
                localStorage.setItem('clientSettings', clientSettingsString);
            } else {
                await cloudStorage.setItem(
                    'clientSettings',
                    clientSettingsString
                );
            }
        },
        []
    );

    const loadSettings = useCallback(
        async (onLoadingFinished: () => void) => {
            try {
                console.log('Loading settings...');
                const [loadedSettings, clientSettings] = await Promise.all([
                    getUserSettingsApi(),
                    getClientSettings()
                ]);

                console.log('Loaded settings from the API:', loadedSettings);
                console.log('Loaded client settings:', clientSettings);

                const combinedSettings = {
                    ...loadedSettings,
                    ...clientSettings
                };

                setSettings(combinedSettings);

                audioManager.setSfxVolume(
                    combinedSettings.sfxVolume ??
                        DEFAULT_CLIENT_SETTINGS.sfxVolume
                );
                audioManager.setWordVolume(
                    combinedSettings.wordVolume ??
                        DEFAULT_CLIENT_SETTINGS.wordVolume
                );
                onLoadingFinished();
            } catch (error) {
                console.error('Failed to load settings', error);
                throw error;
            }
        },
        [getClientSettings]
    );

    // Update the settings via API and update state
    const updateUserSettings = useCallback(
        async (updatedFields: Partial<RemoteSettings>) => {
            const updatedSettings = await updateUserSettingsApi(updatedFields);
            setSettings(prevSettings => ({
                ...prevSettings,
                ...updatedSettings
            }));
        },
        []
    );

    // Update client settings in cloudStorage
    const updateClientSettings = useCallback(
        async (updatedFields: Partial<ClientSettings>) => {
            try {
                const currentClientSettings = await getClientSettings();
                const newClientSettings = {
                    ...currentClientSettings,
                    ...updatedFields
                };
                await saveClientSettings(newClientSettings);

                setSettings(prevSettings => ({
                    ...prevSettings,
                    ...updatedFields
                }));
            } catch (error) {
                console.error('Failed to update client settings', error);
                throw error;
            }
        },
        [getClientSettings, saveClientSettings]
    );

    const setUILanguage = useCallback(
        async (languageCode: string) => {
            await updateUserSettings({ uiLanguage: languageCode });
        },
        [updateUserSettings]
    );

    const setStudyLanguage = useCallback(
        async (languageCode: string) => {
            await updateUserSettings({ studyLanguage: languageCode });
        },
        [updateUserSettings]
    );

    const addNewStudyLanguage = useCallback(
        async (languageCode: string) => {
            const updatedStudyLanguages = [
                ...(settings.studyLanguages || []),
                languageCode
            ];
            await updateUserSettings({ studyLanguages: updatedStudyLanguages });
        },
        [settings.studyLanguages, updateUserSettings]
    );

    const setLanguageLevel = useCallback(
        async (studyLanguage: string, level: string) => {
            await updateLanguageSettingsApi(studyLanguage, level);
        },
        []
    );

    const setSfxVolume = useCallback(
        async (volume: number) => {
            audioManager.setSfxVolume(volume);
            await updateClientSettings({ sfxVolume: volume });
        },
        [updateClientSettings]
    );

    const setWordVolume = useCallback(
        async (volume: number) => {
            audioManager.setWordVolume(volume);
            await updateClientSettings({ wordVolume: volume });
        },
        [updateClientSettings]
    );

    const setIsVibrationOn = useCallback(
        async (isOn: boolean) => {
            await updateClientSettings({ isVibrationOn: isOn });
        },
        [updateClientSettings]
    );

    const setIsSkipWordsIntro = useCallback(
        async (isSkip: boolean) => {
            await updateClientSettings({ isSkipWordsIntro: isSkip });
        },
        [updateClientSettings]
    );

    const updateDevControlsClickCounter = useCallback(() => {
        if (devControlsClickCounter === 4) {
            const newIsShowDevControls = !settings.isShowDevControls;
            updateClientSettings({ isShowDevControls: newIsShowDevControls });
            setDevControlsClickCounter(0);
            emitter.emit('isShowDevControlsChanged', {
                isShowDevControls: newIsShowDevControls
            });
        } else {
            setDevControlsClickCounter(prevCount => prevCount + 1);
        }
    }, [
        devControlsClickCounter,
        settings.isShowDevControls,
        updateClientSettings
    ]);

    const updateIsMagicWandTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateClientSettings({ isMagicWandTooltipNeeded: isNeeded });
        },
        [updateClientSettings]
    );

    const updateIsAudioHintTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateClientSettings({ isAudioHintTooltipNeeded: isNeeded });
        },
        [updateClientSettings]
    );

    const updateIsReviewModeTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateClientSettings({ isReviewModeTooltipNeeded: isNeeded });
        },
        [updateClientSettings]
    );

    const updateIsLeaderboardTooltipNeeded = useCallback(
        async (isNeeded: boolean) => {
            await updateClientSettings({
                isLeaderboardTooltipNeeded: isNeeded
            });
        },
        [updateClientSettings]
    );

    const updateTimezone = useCallback(
        async (timezone: string) => {
            await updateClientSettings({ timezone });
        },
        [updateClientSettings]
    );

    const setIsReviewWithoutSpacedRepViewed = useCallback(async () => {
        await updateClientSettings({ isReviewWithoutSpacedRepViewed: true });
    }, [updateClientSettings]);

    const setIsFriendsModalViewed = useCallback(async () => {
        await updateClientSettings({
            isFriendsModalViewed: true
        });
    }, [updateClientSettings]);

    const setIsChooseTranslationHintViewed = useCallback(async () => {
        await updateClientSettings({
            isChooseTranslationHintViewed: true
        });
    }, [updateClientSettings]);

    const contextValue = useMemo(
        () => ({
            loadSettings,
            settings,
            setUILanguage,
            setStudyLanguage,
            addNewStudyLanguage,
            setLanguageLevel,
            setSfxVolume,
            setWordVolume,
            setIsVibrationOn,
            updateDevControlsClickCounter,
            updateIsMagicWandTooltipNeeded,
            updateIsAudioHintTooltipNeeded,
            updateIsReviewModeTooltipNeeded,
            updateIsLeaderboardTooltipNeeded,
            setIsReviewWithoutSpacedRepViewed,
            setIsFriendsModalViewed,
            setIsChooseTranslationHintViewed,
            updateTimezone,
            setIsSkipWordsIntro
        }),
        [
            loadSettings,
            settings,
            setUILanguage,
            setStudyLanguage,
            addNewStudyLanguage,
            setLanguageLevel,
            setSfxVolume,
            setWordVolume,
            setIsVibrationOn,
            updateDevControlsClickCounter,
            updateIsMagicWandTooltipNeeded,
            updateIsAudioHintTooltipNeeded,
            updateIsReviewModeTooltipNeeded,
            updateIsLeaderboardTooltipNeeded,
            setIsReviewWithoutSpacedRepViewed,
            setIsFriendsModalViewed,
            setIsChooseTranslationHintViewed,
            updateTimezone,
            setIsSkipWordsIntro
        ]
    );

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = (): SettingsContextType => {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within SettingsProvider');
    }
    return context;
};
