import { type FC, useEffect } from 'react';
import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';

import { PhraseInfoLocalized } from '@/types/category';

interface PhrasePanelProps {
    phrase: PhraseInfoLocalized;
    found: boolean;
    onClick?: () => void;
}

const PhraseView: FC<PhrasePanelProps> = ({ phrase, found, onClick }) => {
    const controls = useAnimation();

    useEffect(() => {
        controls.start({
            color: found ? phrase.categoryColor : '#ffffff',
            transition: { duration: 0.5 }
        });
    }, [found, phrase.categoryColor, controls]);

    const getHighlightedSentence = (phrase: PhraseInfoLocalized) => {
        const { sentence, word, categoryColor } = phrase;
        const index = sentence
            .toLocaleLowerCase()
            .indexOf(word.toLocaleLowerCase());

        // If the word is not found in the sentence, return the sentence as is
        if (index === -1) {
            console.warn(`Word "${word}" not found in sentence "${sentence}".`);
            return <span>{sentence}</span>;
        }

        const before = sentence.substring(0, index);
        const matchedWord = sentence.substring(index, index + word.length);
        const after = sentence.substring(index + word.length);

        return (
            <>
                <span style={{ color: categoryColor }}>{before}</span>
                <span>{matchedWord}</span>
                <span style={{ color: categoryColor }}>{after}</span>
            </>
        );
    };

    const isLongSentence = phrase.sentence.length > 35;

    return (
        <div
            className="mb-5 flex flex-col items-center justify-center rounded-3xl bg-black bg-opacity-20 px-4 py-3"
            onClick={onClick}
        >
            <motion.div animate={controls} className="text-center">
                <div
                    className={classNames(
                        'mb-2 font-semibold tracking-wider',
                        isLongSentence ? 'text-lg' : 'text-2xl'
                    )}
                >
                    {getHighlightedSentence(phrase)}
                </div>
            </motion.div>
            <div
                className={classNames(
                    'text-center tracking-wider text-grey50',
                    isLongSentence ? 'text-md' : 'text-lg'
                )}
            >
                {phrase.translation}
            </div>
        </div>
    );
};

export default PhraseView;
