import {
    createContext,
    type FC,
    ReactNode,
    useContext,
    useReducer
} from 'react';

import { CategoryInfoWithProgress, WordInfoLocalized } from '@/types/category';
import { TOTAL_STEPS_PER_GAME_SESSION } from '@/utils/constants';

import { useGameProgress } from './GameProgressContext';

interface NewWordsSessionContextType {
    resetGameSession: () => void;
    gameSession: NewWordsGameSession;
    initGameSession: (
        category: CategoryInfoWithProgress,
        wordsToStudy: WordInfoLocalized[],
        wordsToReview1: WordInfoLocalized[],
        wordsToReview2: WordInfoLocalized[]
    ) => void;
    goToNextGameSessionStep: () => void;
    updateGameSessionCoins: (coins: number) => void;
}

interface NewWordsGameSession {
    isReadyToPlay: boolean;
    category: CategoryInfoWithProgress | null;
    wordsToStudy: WordInfoLocalized[];
    wordsToReview1: WordInfoLocalized[];
    wordsToReview2: WordInfoLocalized[];
    currentStep: number;
    earnedCoins: number;
    totalSteps: number;
    finished: boolean;
    learnedWords: WordInfoLocalized[];
    reviewedWords: WordInfoLocalized[];
}

const NEW_GAME_SESSION: NewWordsGameSession = {
    isReadyToPlay: false,
    category: null,
    wordsToStudy: [],
    wordsToReview1: [],
    wordsToReview2: [],
    currentStep: 1,
    totalSteps: TOTAL_STEPS_PER_GAME_SESSION,
    earnedCoins: 0,
    finished: false,
    learnedWords: [],
    reviewedWords: []
};

type Action =
    | { type: 'RESET' }
    | {
          type: 'INIT';
          payload: {
              category: CategoryInfoWithProgress;
              wordsToStudy: WordInfoLocalized[];
              wordsToReview1: WordInfoLocalized[];
              wordsToReview2: WordInfoLocalized[];
          };
      }
    | { type: 'NEXT_STEP' }
    | { type: 'COMPLETE_SESSION' }
    | { type: 'UPDATE_COINS'; payload: number }
    | {
          type: 'UPDATE_PLAYED_WORDS';
          payload: {
              learnedWords: WordInfoLocalized[];
              reviewedWords: WordInfoLocalized[];
          };
      };

const gameSessionReducer = (
    state: NewWordsGameSession,
    action: Action
): NewWordsGameSession => {
    switch (action.type) {
        case 'RESET':
            return NEW_GAME_SESSION;
        case 'INIT':
            return {
                ...state,
                isReadyToPlay: true,
                category: action.payload.category,
                wordsToStudy: action.payload.wordsToStudy,
                wordsToReview1: action.payload.wordsToReview1,
                wordsToReview2: action.payload.wordsToReview2
            };
        case 'NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1
            };
        case 'COMPLETE_SESSION':
            return {
                ...state,
                finished: true,
                learnedWords: state.wordsToStudy,
                reviewedWords: [
                    ...state.wordsToReview1,
                    ...state.wordsToReview2
                ]
            };
        case 'UPDATE_COINS':
            return {
                ...state,
                earnedCoins: state.earnedCoins + action.payload
            };
        case 'UPDATE_PLAYED_WORDS':
            return {
                ...state,
                learnedWords: action.payload.learnedWords,
                reviewedWords: action.payload.reviewedWords
            };
        default:
            return state;
    }
};

interface NewWordsSessionProviderProps {
    children: ReactNode;
}

const NewWordsSessionContext = createContext<
    NewWordsSessionContextType | undefined
>(undefined);

export const NewWordsSessionProvider: FC<NewWordsSessionProviderProps> = ({
    children
}) => {
    const { resetCategories } = useGameProgress();
    const [gameSession, dispatch] = useReducer(
        gameSessionReducer,
        NEW_GAME_SESSION
    );

    const completeGameSession = () => {
        console.log('completeGameSession');
        resetCategories();

        dispatch({ type: 'COMPLETE_SESSION' });
    };

    const goToNextGameSessionStep = () => {
        if (gameSession.currentStep + 1 > gameSession.totalSteps) {
            return completeGameSession();
        }

        dispatch({ type: 'NEXT_STEP' });
    };

    const updateGameSessionCoins = (coins: number) => {
        dispatch({ type: 'UPDATE_COINS', payload: coins });
    };

    const initGameSession = (
        category: CategoryInfoWithProgress,
        wordsToStudy: WordInfoLocalized[],
        wordsToReview1: WordInfoLocalized[],
        wordsToReview2: WordInfoLocalized[]
    ) => {
        dispatch({
            type: 'INIT',
            payload: {
                category,
                wordsToStudy,
                wordsToReview1,
                wordsToReview2
            }
        });
    };

    const resetGameSession = () => {
        dispatch({ type: 'RESET' });
    };

    return (
        <NewWordsSessionContext.Provider
            value={{
                resetGameSession,
                gameSession,
                initGameSession,
                goToNextGameSessionStep,
                updateGameSessionCoins
            }}
        >
            {children}
        </NewWordsSessionContext.Provider>
    );
};

export const useNewWordsGameSession = (): NewWordsSessionContextType => {
    const context = useContext(NewWordsSessionContext);
    if (!context) {
        throw new Error(
            'useNewWordsSession must be used within a NewWordsSessionProvider'
        );
    }
    return context;
};
