import { type FC, useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { AutoScalingText } from '@/components';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import ShopModal from '@/screens-modal/ShopModal';
import { CategoryInfoWithProgress } from '@/types/category';

import coinIcon from '../../../../assets/images/icons/coin.png';

import UnlockCategoryModal from './UnlockCategoryModal';

interface CategoryRowProps {
    category: CategoryInfoWithProgress;
    /** Called when user chooses or unlocks the category.
     *  `justUnlocked` is true if the category was just unlocked now.
     */
    onChooseCategory: (
        category: CategoryInfoWithProgress,
        justUnlocked: boolean
    ) => void;
}

export const CategoryRow: FC<CategoryRowProps> = ({
    category,
    onChooseCategory
}) => {
    const [isUnlockDialogueVisible, setUnlockDialogueVisible] = useState(false);
    const [isShowShop, setIsShowShop] = useState(false);

    const { categoryInfo, categoryProgressItem } = category;
    const { gameProgress, unlockCategory, addCoins } = useGameProgress();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    // Calculate total items and progress
    const totalWords = categoryInfo.words.length;
    const wordsLearnt = categoryProgressItem.wordProgressItems.length;

    const totalPhrases = categoryInfo.phrases.length;
    const phrasesLearnt = categoryProgressItem.phraseProgressItems.length;

    const wordsProgress = totalWords > 0 ? wordsLearnt / totalWords : 0;
    const phrasesProgress = totalPhrases > 0 ? phrasesLearnt / totalPhrases : 0;

    // Check if locked
    const locked =
        !categoryInfo.startFriendly && !categoryProgressItem.unlocked;

    // Price to unlock the category
    const unlockCategoryPrice =
        totalWords * gameConfig.pricePerWordInCategory +
        totalPhrases * gameConfig.pricePerPhraseInCategory;

    const onChooseCategoryPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        ReactGA.event({
            category: 'Category',
            action: 'Press category (row) button',
            label: categoryInfo.id
        });

        if (locked) {
            if (gameProgress.coins >= unlockCategoryPrice) {
                setUnlockDialogueVisible(true);
            } else {
                ReactGA.event({
                    category: 'Shop',
                    action: 'Open shop. Not enough coins to unlock category'
                });
                setIsShowShop(true);
            }
        } else {
            onChooseCategory(category, false);
        }
    };

    const [unlockState, doUnlock] = useAsyncFn(async () => {
        ReactGA.event({
            category: 'Category',
            action: 'Unlock category'
        });
        try {
            // Unlock the category
            await unlockCategory(
                settings.studyLanguage,
                settings.languageLevel,
                categoryInfo.id
            );

            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Done });

            setUnlockDialogueVisible(false);

            // Deduct coins after unlocking
            await addCoins(-unlockCategoryPrice);
            onChooseCategory(category, true);
        } catch (error) {
            console.error('Error unlocking category:', unlockState.error);
            toast.error(getLabel('error.failed-to-unlock-category'));
        }
    }, [
        unlockCategory,
        addCoins,
        settings.studyLanguage,
        settings.languageLevel,
        categoryInfo.id,
        unlockCategoryPrice,
        vibrateLight
    ]);

    const onUnlockClose = () => {
        ReactGA.event({
            category: 'Category',
            action: 'Close unlock category modal'
        });
        setUnlockDialogueVisible(false);
    };

    return (
        <motion.div
            whileTap={{ scale: 0.95 }}
            initial={{ scale: 1 }}
            animate={{ scale: 1 }}
            className={classNames(
                'relative flex w-full flex-col rounded-lg p-3'
            )}
            onClick={onChooseCategoryPress}
        >
            <div className="flex items-center">
                {/* Icon/Thumbnail on the left */}
                <div
                    className={classNames(
                        'relative h-[96px] w-[96px] flex-shrink-0 overflow-hidden rounded-[24px] p-4',
                        locked ? 'opacity-60' : 'opacity-100'
                    )}
                    style={{ backgroundColor: categoryInfo.color }}
                >
                    {/* LEVEL TAG on top (absolute) */}
                    <div className="absolute left-1/2 top-3 -translate-x-1/2 rounded-md bg-black/70 px-2 opacity-60">
                        <AutoScalingText
                            maxFontSize={10}
                            style={{
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                            text={getLabel(
                                `level.${categoryInfo.languageLevel.toLowerCase()}`
                            ).toLowerCase()}
                        />
                    </div>

                    <img
                        src={categoryInfo.imageUrl}
                        alt={categoryInfo.name}
                        className="aspect-square h-full w-full object-contain"
                    />
                    {/* Lock overlay if locked */}
                    {locked && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center rounded-[24px] bg-black/60">
                            <div className="flex items-center rounded-full bg-black/40 px-2 py-1">
                                <img
                                    src={coinIcon}
                                    alt="Coins"
                                    className={classNames(
                                        'mr-1 h-5 w-5',
                                        gameProgress.coins <
                                            unlockCategoryPrice &&
                                            'brightness-75 grayscale filter'
                                    )}
                                />
                                <span
                                    className={classNames(
                                        'font-bold',
                                        gameProgress.coins < unlockCategoryPrice
                                            ? 'text-gray-400'
                                            : 'text-white'
                                    )}
                                >
                                    {unlockCategoryPrice}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                {/* Category name on the right */}
                <div
                    className={classNames(
                        'ml-3 flex w-full min-w-0 flex-col items-start overflow-hidden',
                        locked ? 'opacity-70' : 'opacity-100'
                    )}
                >
                    <AutoScalingText
                        maxFontSize={28}
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            lineHeight: '1.1',
                            letterSpacing: '1px',
                            fontFamily: 'Nunito'
                        }}
                        maxLines={3}
                        text={categoryInfo.name}
                    />

                    <div className="mt-3 grid w-full grid-cols-[auto,1fr] items-center gap-y-2">
                        {/* Words Label */}
                        <span className="mr-2 text-xs font-bold text-white/80">
                            {getLabel('common.words')}
                        </span>
                        {/* Words Progress Bar */}
                        <div className="relative h-4 w-full overflow-hidden rounded-md bg-white/15">
                            <div
                                className="absolute left-0 top-0 h-full rounded-md bg-black/60"
                                style={{ width: `${wordsProgress * 100}%` }}
                            />
                            <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
                                {wordsLearnt}/{totalWords}
                            </span>
                        </div>

                        {/* Phrases Label */}
                        {totalPhrases > 0 && (
                            <>
                                <span className="mr-2 text-xs font-bold text-white/80">
                                    {getLabel('common.phrases')}
                                </span>
                                {/* Phrases Progress Bar */}
                                <div className="relative h-4 w-full overflow-hidden rounded-md rounded-bl-none bg-white/15">
                                    <div
                                        className="absolute left-0 top-0 h-full rounded-md rounded-bl-none bg-black/60"
                                        style={{
                                            width: `${phrasesProgress * 100}%`
                                        }}
                                    />
                                    <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
                                        {phrasesLearnt}/{totalPhrases}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Unlock Dialogue */}
            <UnlockCategoryModal
                isVisible={isUnlockDialogueVisible}
                onButton1Press={doUnlock}
                onButton2Press={onUnlockClose}
                onClose={onUnlockClose}
                button1Loading={unlockState.loading}
                unlockCategoryPrice={unlockCategoryPrice}
            />

            {/* Shop Modal for insufficient coins */}
            <ShopModal
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
        </motion.div>
    );
};
