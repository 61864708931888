import { Howl } from 'howler';

import card from '../../assets/audio/card.m4a';
import click from '../../assets/audio/click.m4a';
import click2 from '../../assets/audio/click2.m4a';
import coin from '../../assets/audio/coin.m4a';
import done from '../../assets/audio/done.m4a';
import notice from '../../assets/audio/notice.m4a';
import purchaseSuccess from '../../assets/audio/purchase-success.m4a';
import reveal from '../../assets/audio/reveal.m4a';
import success from '../../assets/audio/success.m4a';
import wrong from '../../assets/audio/wrong.m4a';
import emitter from '../events/emitter';

export enum SoundName {
    Card = 'card',
    Click = 'click',
    Click2 = 'click2',
    Coin = 'coin',
    Done = 'done',
    Notice = 'notice',
    Reveal = 'reveal',
    Success = 'success',
    Wrong = 'wrong',
    PurchaseSuccess = 'purchaseSuccess'
}

const soundFileMap: { [key in SoundName]: string } = {
    [SoundName.Card]: card,
    [SoundName.Click]: click,
    [SoundName.Click2]: click2,
    [SoundName.Coin]: coin,
    [SoundName.Done]: done,
    [SoundName.Notice]: notice,
    [SoundName.Reveal]: reveal,
    [SoundName.Success]: success,
    [SoundName.Wrong]: wrong,
    [SoundName.PurchaseSuccess]: purchaseSuccess
};

class AudioManager {
    private wordAudio: Howl | null;
    private soundAudioMap: Map<SoundName, Howl>;
    private sfxVolume: number = 1; // Sound effects volume (0.0 - 1.0)
    private wordVolume: number = 1; // Word audio volume (0.0 - 1.0)
    private isInitialized: boolean = false;

    constructor() {
        this.wordAudio = null;
        this.soundAudioMap = new Map();
    }

    public init() {
        if (!this.isInitialized) {
            this.preloadSounds();
            this.subscribeToEvents();
            this.isInitialized = true;
        }
    }

    private preloadSounds() {
        for (const [soundName, soundUrl] of Object.entries(soundFileMap)) {
            const sound = new Howl({
                src: [soundUrl],
                preload: true,
                volume: this.sfxVolume
            });
            this.soundAudioMap.set(soundName as SoundName, sound);
        }
    }

    private subscribeToEvents() {
        emitter.on('playSound', this.handlePlaySound);
        emitter.on('playAudioByUrl', this.handlePlayAudioByUrl);
    }

    /**
     * Set the volume for sound effects. Input volume is in percentage form (0-100).
     */
    public setSfxVolume(volume: number) {
        this.sfxVolume = volume / 100;
        this.soundAudioMap.forEach(sound => {
            sound.volume(this.sfxVolume);
        });
    }

    /**
     * Set the volume for word audio. Input volume is in percentage form (0-100).
     */
    public setWordVolume(volume: number) {
        this.wordVolume = volume / 100;
        if (this.wordAudio) {
            this.wordAudio.volume(this.wordVolume);
        }
    }

    private handlePlaySound = ({ sound }: { sound: SoundName }) => {
        this.playSound(sound);
    };

    private handlePlayAudioByUrl = ({
        audioUrl,
        onPlaybackFinished
    }: {
        audioUrl: string;
        onPlaybackFinished?: () => void;
    }) => {
        this.playAudioByUrl(audioUrl, onPlaybackFinished);
    };

    playSound(soundName: SoundName) {
        const sound = this.soundAudioMap.get(soundName);
        if (sound) {
            sound.stop();
            sound.play();
        } else {
            console.error('Sound not found:', soundName);
        }
    }

    playClickAtVolume(volume: number) {
        const sound = new Howl({
            src: [click2],
            preload: true,
            volume: volume / 100
        });
        sound.play();
    }

    async playAudioByUrl(audioUrl: string, onPlaybackFinished?: () => void) {
        if (this.wordAudio) {
            this.wordAudio.stop();
        }

        this.wordAudio = new Howl({
            src: [audioUrl],
            volume: this.wordVolume,
            onend: () => {
                if (onPlaybackFinished) {
                    onPlaybackFinished();
                }
            }
        });

        this.wordAudio.play();
    }

    public cleanup() {
        console.log('[AudioManager] Cleaning up AudioManager resources...');

        // Remove event listeners
        console.log(
            '[AudioManager] Unsubscribing from events: playSound, playAudioByUrl'
        );
        emitter.off('playSound', this.handlePlaySound);
        emitter.off('playAudioByUrl', this.handlePlayAudioByUrl);

        // Unload all preloaded sounds
        this.soundAudioMap.forEach((sound, name) => {
            console.log(`[AudioManager] Unloading sound "${name}".`);
            sound.unload();
        });
        this.soundAudioMap.clear();

        this.wordAudio?.unload();

        this.isInitialized = false;
        console.log(
            '[AudioManager] Cleanup complete. AudioManager is now uninitialized.'
        );
    }
}

export const audioManager = new AudioManager();
