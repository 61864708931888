import { type FC, useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { SoundName } from '@/audio/AudioManager';
import { AutoScalingText } from '@/components';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import ShopModal from '@/screens-modal/ShopModal';
import { CategoryInfoWithProgress } from '@/types/category';

import coinIcon from '../../../../assets/images/icons/coin.png';

import UnlockCategoryModal from './UnlockCategoryModal';

interface CategoryCardProps {
    category: CategoryInfoWithProgress;
    onChooseCategory: (
        category: CategoryInfoWithProgress,
        justUnlocked: boolean
    ) => void;
    insideList?: boolean;
}

export const CategoryCard: FC<CategoryCardProps> = ({
    category,
    onChooseCategory,
    insideList
}) => {
    const [isUnlockDialogueVisible, setUnlockDialogueVisible] = useState(false);
    const [isShowShop, setIsShowShop] = useState(false);

    const { categoryInfo, categoryProgressItem } = category;
    const { gameProgress, unlockCategory, addCoins } = useGameProgress();
    const { settings } = useSettings();
    const { gameConfig } = useGameConfig();
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();

    // Calculate total items and progress
    const totalWords = categoryInfo.words.length;
    const wordsLearnt = categoryProgressItem.wordProgressItems.length;

    const totalPhrases = categoryInfo.phrases.length;
    const phrasesLearnt = categoryProgressItem.phraseProgressItems.length;

    const wordsProgress = totalWords > 0 ? wordsLearnt / totalWords : 0;
    const phrasesProgress = totalPhrases > 0 ? phrasesLearnt / totalPhrases : 0;

    const wordsCompleted =
        categoryProgressItem.wordProgressItems.length ===
        categoryInfo.words.length;

    const locked =
        !categoryInfo.startFriendly && !categoryProgressItem.unlocked;

    const unlockCategoryPrice =
        categoryInfo.words.length * gameConfig.pricePerWordInCategory +
        categoryInfo.phrases.length * gameConfig.pricePerPhraseInCategory;

    const onChooseCategoryPress = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();

        ReactGA.event({
            category: 'Category',
            action: 'Press category button',
            label: categoryInfo.id
        });

        if (locked) {
            if (gameProgress.coins >= unlockCategoryPrice) {
                setUnlockDialogueVisible(true);
            } else {
                ReactGA.event({
                    category: 'Shop',
                    action: 'Open shop. Not enough coins to unlock category'
                });
                setIsShowShop(true);
            }
        } else {
            onChooseCategory(category, false);
        }
    };

    const [unlockState, doUnlock] = useAsyncFn(async () => {
        ReactGA.event({
            category: 'Category',
            action: 'Unlock category'
        });
        try {
            // Unlock the category
            await unlockCategory(
                settings.studyLanguage,
                settings.languageLevel,
                categoryInfo.id
            );

            vibrateLight();
            emitter.emit('playSound', { sound: SoundName.Done });

            setUnlockDialogueVisible(false);

            // Deduct coins after unlocking
            await addCoins(-unlockCategoryPrice);
            onChooseCategory(category, true);
        } catch (error) {
            console.error('Error unlocking category:', unlockState.error);
            toast.error(getLabel('error.failed-to-unlock-category'));
        }
    }, [
        unlockCategory,
        addCoins,
        settings.studyLanguage,
        settings.languageLevel,
        categoryInfo.id,
        unlockCategoryPrice,
        vibrateLight
    ]);

    const onUnlockClose = () => {
        ReactGA.event({
            category: 'Category',
            action: 'Close unlock category modal'
        });
        setUnlockDialogueVisible(false);
    };

    return (
        <div
            className={classNames(
                'flex flex-col items-center justify-center',
                insideList ? 'w-full' : 'w-[31%]'
            )}
        >
            <motion.div
                whileTap={{ scale: 0.95 }}
                initial={{ scale: 1 }}
                animate={{ scale: 1 }}
                className="relative aspect-square w-full"
            >
                {/* Background Layer */}
                <div
                    className={classNames(
                        'absolute top-1 aspect-square w-full rounded-[32px]',
                        wordsCompleted ? 'rounded-bl-[0px]' : '',
                        locked ? 'opacity-15' : 'opacity-50'
                    )}
                    style={{ backgroundColor: categoryInfo.color }}
                ></div>

                {/* Category Button */}
                <button
                    className={classNames(
                        'relative flex h-full w-full items-center justify-center rounded-[32px] p-5 transition-transform duration-150 ease-in-out',
                        wordsCompleted ? 'rounded-bl-[0px]' : ''
                    )}
                    style={{ backgroundColor: categoryInfo.color }}
                    onClick={onChooseCategoryPress}
                >
                    <div className="absolute left-1/2 top-3 z-10 -translate-x-1/2 rounded-md bg-black/70 px-2 opacity-60">
                        <AutoScalingText
                            maxFontSize={10}
                            style={{
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                            text={getLabel(
                                `level.${categoryInfo.languageLevel.toLowerCase()}`
                            ).toLowerCase()}
                        />
                    </div>

                    <img
                        src={categoryInfo.imageUrl}
                        alt={categoryInfo.name}
                        className="aspect-square h-full w-full object-contain"
                    />

                    {/* Lock Overlay */}
                    {locked && (
                        <div className="absolute inset-0 flex flex-col items-center justify-center rounded-[32px] bg-black/60 pt-1.5">
                            <div className="flex items-center rounded-full bg-black/40 px-1 py-0.5">
                                <img
                                    src={coinIcon}
                                    alt="Coins"
                                    className={classNames(
                                        'mr-1 h-6 w-6',
                                        // Apply grayscale if user cannot afford
                                        gameProgress.coins <
                                            unlockCategoryPrice &&
                                            'brightness-75 grayscale filter'
                                    )}
                                />
                                <span
                                    className={classNames(
                                        'font-bold',
                                        // Apply gray text if user cannot afford
                                        gameProgress.coins < unlockCategoryPrice
                                            ? 'text-gray-400'
                                            : 'text-white'
                                    )}
                                >
                                    {unlockCategoryPrice}
                                </span>
                            </div>
                        </div>
                    )}
                </button>
            </motion.div>

            {/* Category Name */}
            <div className="mt-3 w-full text-center">
                <AutoScalingText
                    maxFontSize={16}
                    style={{
                        color: 'white',
                        fontWeight: 'bold',
                        lineHeight: '1.25',
                        letterSpacing: '1px'
                    }}
                    maxLines={3}
                    text={categoryInfo.name}
                />
            </div>

            <div className="mt-1.5 flex w-full">
                {/* Words Progress Bar */}
                <div className="relative mr-1.5 h-5 w-full overflow-hidden rounded-full bg-white/15">
                    <div
                        className="h-full rounded-full bg-black/60"
                        style={{ width: `${wordsProgress * 100}%` }}
                    />
                    <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
                        {`${wordsLearnt}/${totalWords}`}
                    </span>
                </div>

                {/* Phrases Progress Bar */}
                {totalPhrases > 0 && (
                    <div className="relative h-5 w-full overflow-hidden rounded-xl rounded-bl-none bg-white/15">
                        <div
                            className="h-full rounded-full rounded-bl-none bg-black/60"
                            style={{ width: `${phrasesProgress * 100}%` }}
                        />
                        <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-white">
                            {`${phrasesLearnt}/${totalPhrases}`}
                        </span>
                    </div>
                )}
            </div>

            {/* Unlock Dialogue */}
            <UnlockCategoryModal
                isVisible={isUnlockDialogueVisible}
                onButton1Press={doUnlock}
                onButton2Press={onUnlockClose}
                onClose={onUnlockClose}
                button1Loading={unlockState.loading}
                unlockCategoryPrice={unlockCategoryPrice}
            />

            {/* Shop Modal */}
            <ShopModal
                isVisible={isShowShop}
                onClose={() => setIsShowShop(false)}
                notEnoughCoinsMode={true}
            />
        </div>
    );
};
