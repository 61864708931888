import {
    createContext,
    type FC,
    ReactNode,
    useCallback,
    useContext,
    useState
} from 'react';

import {
    claimFriendReferralRewardApi,
    claimStreakRewardApi,
    getAllProgressApi,
    getStreakApi,
    getUserApi,
    updateCoinsApi,
    updateGameLevelApi,
    updateLandscapeProgressApi,
    updateLandscapesProgressApi,
    updateSelectedLandscapeIdApi,
    updateStreakApi,
    updateWordsProgressApi,
    updateXpApi
} from '@/api/client';
import { loadWordBoxWords, saveWordBoxWords } from '@/api/localDb';
import { Friend } from '@/api/types';
import { useContent } from '@/context';
import { WORDS_TO_COMPLETE_BUCKET } from '@/utils/constants';
import { parseDate } from '@/utils/date';

import {
    CategoryInfoWithProgress,
    PhraseInfoLocalized,
    WordInfoLocalized
} from '../types/category';
import { LandscapeInfoLocalized } from '../types/prize';
import {
    CategoryProgressItem,
    GameProgressValues,
    LandscapeProgressItem,
    LandscapesUnlocked,
    PhraseProgressItem,
    Streak,
    WordProgressItem
} from '../types/progress';
import {
    getCategoryToStudy,
    getPhrasesToStudyForCurrentCategory,
    getUniqueCleanWords,
    getWordsToStudyForCurrentCategory
} from '../utils/categories';
import { Random } from '../utils/Random';
import {
    getAllPhrasesInProgress,
    getAllPhrasesToReview,
    getAllPhrasesToReviewCount,
    getAllWordsInProgress,
    getAllWordsToReview,
    getAllWordsToReviewCount
} from '../utils/review';

interface GameProgressContextType {
    categoryToStudy?: CategoryInfoWithProgress | null;
    chooseCategory: (category: CategoryInfoWithProgress) => void;
    resetGameProgressState: () => void;
    resetCategories: () => void;
    currentCategory?: CategoryInfoWithProgress;
    gameProgress: GameProgress;
    streak: Streak;
    friends: Friend[];
    claimStreakReward: () => Promise<void>;
    claimFriendReferralReward: (friendId: string) => Promise<void>;
    updateStreak: () => Promise<Streak>;
    getWordsLearnedCount: () => number;
    getPhrasesLearnedCount: () => number;
    getWordsToReview: (studyLanguage: string) => WordInfoLocalized[];
    getPhrasesToReview: () => PhraseInfoLocalized[];
    getWordsToReviewCount: () => number;
    getPhrasesToReviewCount: () => number;
    goToNextGameLevel: (studyLanguage: string) => Promise<void>;
    incrementLandscapeProgress: (studyLanguage: string) => Promise<void>;
    isLockedLandscapeAvailable: () => boolean;
    isNoWordsToReviewWithSpacedRep: boolean;
    isNoPhrasesToReviewWithSpacedRep: boolean;
    loadCategoryToStudy: (languageLevel: string) => void;
    loadGameProgress: (
        studyLanguage: string,
        onLoadingFinished: () => void
    ) => Promise<void>;
    nextLandscape?: LandscapeInfoLocalized;
    prepareNextLandscape: () => void;
    resetNextLandscape: () => void;
    unlockCategory: (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => Promise<void>;
    unlockLandscape: (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => Promise<void>;
    updateReviewProgress: (
        studyLanguage: string,
        reviewedWords: WordInfoLocalized[],
        reviewedPhrases: PhraseInfoLocalized[]
    ) => Promise<void>;
    updateCategoriesProgress: (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        learnedPhrases: PhraseInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => Promise<void>;
    updateSelectedLandscapeId: (
        studyLanguage: string,
        landscapeId: string
    ) => void;
    addXp: (studyLanguage: string, xp: number) => Promise<void>;
    addCoins: (coins: number) => Promise<void>;
    updateCoinsState: (coinsToAdd: number) => void;
    updateIsNoWordsToReviewWithSpacedRep: (
        noWordsToReviewWithSpacedRep: boolean
    ) => void;
    updateIsNoPhrasesToReviewWithSpacedRep: (
        noPhrasesToReviewWithSpacedRep: boolean
    ) => void;
    wordsToStudy?: WordInfoLocalized[];
    phrasesToStudy?: PhraseInfoLocalized[];
    wordBoxWords: string[];
    addWordToWordBox: (studyLanguage: string, word: string) => string[];
    claimWordBoxReward: (studyLanguage: string) => Promise<void>;
}

export interface GameProgress extends GameProgressValues {
    categoriesProgress: CategoryInfoWithProgress[];
    landscapesProgress: LandscapeProgressItem[];
    landscapesUnlocked: LandscapesUnlocked;
}

interface GameProgressProviderProps {
    children: ReactNode;
}

const GameProgressContext = createContext<GameProgressContextType | undefined>(
    undefined
);

export const GameProgressProvider: FC<GameProgressProviderProps> = ({
    children
}) => {
    const { categories, landscapes } = useContent();

    const [gameProgress, setGameProgress] = useState<GameProgress>();
    const [streak, setStreak] = useState<Streak>();
    const [friends, setFriends] = useState<Friend[]>();
    const [isNoWordsToReviewWithSpacedRep, setIsNoWordsToReviewWithSpacedRep] =
        useState(false);
    const [
        isNoPhrasesToReviewWithSpacedRep,
        setIsNoPhrasesToReviewWithSpacedRep
    ] = useState(false);

    const [categoryToStudy, setCategoryToStudy] =
        useState<CategoryInfoWithProgress>();
    const [currentCategory, setCurrentCategory] =
        useState<CategoryInfoWithProgress>();
    const [wordsToStudy, setWordsToStudy] = useState<WordInfoLocalized[]>();
    const [phrasesToStudy, setPhrasesToStudy] =
        useState<PhraseInfoLocalized[]>();
    const [wordBoxWords, setWordBoxWords] = useState<string[]>([]);

    const [nextLandscape, setNextLandscape] =
        useState<LandscapeInfoLocalized>();

    const processCategoriesProgress = (
        allCategoryProgress: CategoryProgressItem[]
    ): CategoryInfoWithProgress[] => {
        const categoriesWithProgress: CategoryInfoWithProgress[] = [];

        console.log('Total amount of categories: ' + categories.length);

        for (const category of categories) {
            // Find the progress for the current category from the fetched data
            let categoryProgressItem: CategoryProgressItem =
                allCategoryProgress.find(
                    progressItem => progressItem.categoryId === category.id
                );

            // If no progress is found for the category, initialize it
            if (!categoryProgressItem) {
                categoryProgressItem = {
                    categoryId: category.id,
                    wordProgressItems: [],
                    phraseProgressItems: [],
                    unlocked: false
                };
            }

            // Normalize dates
            categoryProgressItem.lastTimePlayed = parseDate(
                categoryProgressItem.lastTimePlayed
            );
            categoryProgressItem.unlockedAt = parseDate(
                categoryProgressItem.unlockedAt
            );
            categoryProgressItem.unlocked =
                !!categoryProgressItem.unlocked ||
                categoryProgressItem.unlockedAt !== undefined;

            // Normalize word progress item dates
            categoryProgressItem.wordProgressItems =
                categoryProgressItem.wordProgressItems.map(wpi => ({
                    ...wpi,
                    firstTimePlayed: parseDate(wpi.firstTimePlayed),
                    lastTimePlayed: parseDate(wpi.lastTimePlayed),
                    movedToBoxAt: parseDate(wpi.movedToBoxAt)
                }));

            // Normalize phrase progress item dates
            categoryProgressItem.phraseProgressItems =
                categoryProgressItem.phraseProgressItems.map(ppi => ({
                    ...ppi,
                    firstTimePlayed: parseDate(ppi.firstTimePlayed),
                    lastTimePlayed: parseDate(ppi.lastTimePlayed),
                    movedToBoxAt: parseDate(ppi.movedToBoxAt)
                }));

            // Build CategoryInfoWithProgress structure
            const categoryInfoWithProgress: CategoryInfoWithProgress = {
                categoryInfo: category,
                categoryProgressItem
            };

            // Filter word progress to only include words that still exist in the category's content
            categoryInfoWithProgress.categoryProgressItem.wordProgressItems =
                categoryInfoWithProgress.categoryProgressItem.wordProgressItems.filter(
                    wordProgressItem =>
                        category.words.some(
                            wordInfo => wordInfo.id === wordProgressItem.wordId
                        )
                );

            // Filter phrase progress to only include phrases that still exist in the category's content
            categoryInfoWithProgress.categoryProgressItem.phraseProgressItems =
                categoryInfoWithProgress.categoryProgressItem.phraseProgressItems.filter(
                    phraseProgressItem =>
                        category.phrases.some(
                            phraseInfo =>
                                phraseInfo.id === phraseProgressItem.phraseId
                        )
                );

            categoriesWithProgress.push(categoryInfoWithProgress);
        }

        console.log(
            'loadCategoriesProgress finished. Amount of categoriesWithProgress: ' +
                categoriesWithProgress.length
        );

        return categoriesWithProgress;
    };

    const loadGameProgress = useCallback(
        async (studyLanguage: string, onLoadingFinished: () => void) => {
            console.log('Loading game progress...');

            resetCurrentValues();

            const [
                { landscapesProgress, categoriesProgress, languageProgress },
                { friends },
                streak
            ] = await Promise.all([
                getAllProgressApi(studyLanguage),
                getUserApi(),
                getStreakApi()
            ]);

            const wordBoxWords = loadWordBoxWords(studyLanguage);
            setWordBoxWords(wordBoxWords);

            const allCategoriesProgress =
                processCategoriesProgress(categoriesProgress);

            setGameProgress({
                gameLevel: languageProgress.gameLevel,
                coins: languageProgress.coins,
                xp: languageProgress.xp,
                landscapeProgress: languageProgress.landscapeProgress,
                selectedLandscapeId: languageProgress.selectedLandscapeId,
                categoriesProgress: allCategoriesProgress,
                landscapesProgress: landscapesProgress,
                landscapesUnlocked: {
                    unlocked: landscapesProgress.length,
                    total: landscapes.length
                }
            });

            setFriends(friends);
            setStreak(streak);

            console.log('Game progress loaded successfully');
            onLoadingFinished();
        },
        [categories, landscapes]
    );

    const loadCategoryToStudy = (languageLevel: string) => {
        console.log('loadCategoryToStudy');
        console.log(
            'categoriesProgress items: ' +
                gameProgress.categoriesProgress.length
        );

        const category = getCategoryToStudy(
            gameProgress.categoriesProgress,
            languageLevel
        );
        setCategoryToStudy(category);
    };

    const unlockCategory = async (
        studyLanguage: string,
        languageLevel: string,
        categoryId: string
    ) => {
        const categoriesProgress = gameProgress.categoriesProgress;
        const categoryProgress = categoriesProgress.find(
            cp => cp.categoryInfo.id === categoryId
        );

        if (categoryProgress) {
            categoryProgress.categoryProgressItem.unlocked = true;
            categoryProgress.categoryProgressItem.unlockedAt = new Date();

            await updateWordsProgressApi(studyLanguage, [
                categoryProgress.categoryProgressItem
            ]);

            setGameProgress(prev => ({ ...prev, categoriesProgress }));
        } else {
            console.error(
                'Category progress is for some reason does not exist'
            );
        }

        // update categories to choose from to reflect the unlocked category
        loadCategoryToStudy(languageLevel);
    };

    const chooseCategory = (category: CategoryInfoWithProgress) => {
        setCurrentCategory(category);
        setWordsToStudy(getWordsToStudyForCurrentCategory(category));
        setPhrasesToStudy(getPhrasesToStudyForCurrentCategory(category));
    };

    const updateSelectedLandscapeId = async (
        studyLanguage: string,
        landscapeId: string
    ) => {
        await updateSelectedLandscapeIdApi(studyLanguage, landscapeId);
        setGameProgress(prevGameProgress => ({
            ...prevGameProgress,
            selectedLandscapeId: landscapeId
        }));
    };

    const addXp = async (studyLanguage: string, xp: number) => {
        const updatedXp = gameProgress.xp + xp;
        await updateXpApi(studyLanguage, updatedXp);
        setGameProgress(prev => ({ ...prev, xp: updatedXp }));
    };

    const getWordsLearnedCount = () => {
        return gameProgress.categoriesProgress.reduce(
            (totalWordsLearned, categoryProgress) => {
                return (
                    totalWordsLearned +
                    categoryProgress.categoryProgressItem.wordProgressItems
                        .length
                );
            },
            0
        );
    };

    const getPhrasesLearnedCount = () => {
        return gameProgress.categoriesProgress.reduce(
            (totalPhrasesLearned, categoryProgress) => {
                return (
                    totalPhrasesLearned +
                    categoryProgress.categoryProgressItem.phraseProgressItems
                        .length
                );
            },
            0
        );
    };

    const addCoins = async (coins: number) => {
        const updatedCoins = gameProgress.coins + coins;
        await updateCoinsApi(updatedCoins);
        setGameProgress(prev => ({ ...prev, coins: updatedCoins }));
    };

    const updateCoinsState = (coinsToAdd: number) => {
        setGameProgress(prev => ({
            ...prev,
            coins: prev.coins + coinsToAdd
        }));
    };

    const resetCategories = () => {
        setCategoryToStudy(undefined);
        setCurrentCategory(undefined);
    };

    const resetWordBoxWords = () => {
        setWordBoxWords([]);
    };

    const resetCurrentValues = useCallback(() => {
        resetCategories();
        resetWordBoxWords();
        setWordsToStudy(undefined);
        setPhrasesToStudy(undefined);
        setNextLandscape(undefined);
        setGameProgress(undefined);
    }, [setWordBoxWords, setNextLandscape, setGameProgress]);

    const resetGameProgressState = useCallback(() => {
        resetCurrentValues();
    }, [resetCurrentValues]);

    const addWordToWordBox = useCallback(
        (studyLanguage: string, word: string) => {
            if (
                wordBoxWords.length >= WORDS_TO_COMPLETE_BUCKET ||
                wordBoxWords.includes(word)
            )
                return wordBoxWords;

            const updatedWords = [...wordBoxWords, word];
            saveWordBoxWords(studyLanguage, updatedWords);
            setWordBoxWords(updatedWords);

            return updatedWords;
        },
        [wordBoxWords, setWordBoxWords]
    );

    const claimWordBoxReward = useCallback(
        async (studyLanguage: string) => {
            const words = wordBoxWords;

            if (words.length === 0) {
                return;
            }

            await addXp(studyLanguage, 500);
            resetWordBoxWords();
            saveWordBoxWords(studyLanguage, []);
        },
        [wordBoxWords, addXp]
    );

    const updateReviewProgress = useCallback(
        async (
            studyLanguage: string,
            correctWords: WordInfoLocalized[],
            correctPhrases: PhraseInfoLocalized[]
        ) => {
            if (!gameProgress) {
                console.error('GameProgress is not loaded yet.');
                return;
            }

            const currentTime = new Date();

            // Create a Map for efficient access and immutability
            const updatedCategoriesMap = new Map<
                string,
                CategoryInfoWithProgress
            >(
                gameProgress.categoriesProgress.map(cp => [
                    cp.categoryInfo.id,
                    { ...cp }
                ])
            );

            // Maps to store updated word and phrase progress items by category
            const updatedWordProgressItemsByCategory = new Map<
                string,
                WordProgressItem[]
            >();
            const updatedPhraseProgressItemsByCategory = new Map<
                string,
                PhraseProgressItem[]
            >();

            // Process correct words
            for (const word of correctWords) {
                const categoryId = word.categoryId;
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId);

                if (categoryInfoWithProgress) {
                    const categoryProgressItem =
                        categoryInfoWithProgress.categoryProgressItem;

                    // Find existing word progress
                    const existingWordProgressIndex =
                        categoryProgressItem.wordProgressItems.findIndex(
                            wpi => wpi.wordId === word.id
                        );

                    if (existingWordProgressIndex !== -1) {
                        // Update existing word progress item immutably
                        const existingWordProgress =
                            categoryProgressItem.wordProgressItems[
                                existingWordProgressIndex
                            ];
                        const updatedWordProgress: WordProgressItem = {
                            ...existingWordProgress,
                            box: existingWordProgress.box + 1,
                            movedToBoxAt: currentTime
                        };

                        // Update word progress items immutably
                        categoryProgressItem.wordProgressItems =
                            categoryProgressItem.wordProgressItems.map(wpi =>
                                wpi.wordId === word.id
                                    ? updatedWordProgress
                                    : wpi
                            );

                        // Add the updated word progress to the map
                        if (
                            !updatedWordProgressItemsByCategory.has(categoryId)
                        ) {
                            updatedWordProgressItemsByCategory.set(
                                categoryId,
                                []
                            );
                        }
                        updatedWordProgressItemsByCategory
                            .get(categoryId)!
                            .push(updatedWordProgress);
                    }
                }
            }

            // Process correct phrases
            for (const phrase of correctPhrases) {
                const categoryId = phrase.categoryId;
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId);

                if (categoryInfoWithProgress) {
                    const categoryProgressItem =
                        categoryInfoWithProgress.categoryProgressItem;

                    // Find existing phrase progress
                    const existingPhraseProgressIndex =
                        categoryProgressItem.phraseProgressItems.findIndex(
                            ppi => ppi.phraseId === phrase.id
                        );

                    if (existingPhraseProgressIndex !== -1) {
                        // Update existing phrase progress item immutably
                        const existingPhraseProgress =
                            categoryProgressItem.phraseProgressItems[
                                existingPhraseProgressIndex
                            ];
                        const updatedPhraseProgress: PhraseProgressItem = {
                            ...existingPhraseProgress,
                            box: existingPhraseProgress.box + 1,
                            movedToBoxAt: currentTime
                        };

                        // Update phrase progress items immutably
                        categoryProgressItem.phraseProgressItems =
                            categoryProgressItem.phraseProgressItems.map(ppi =>
                                ppi.phraseId === phrase.id
                                    ? updatedPhraseProgress
                                    : ppi
                            );

                        // Add the updated phrase progress to the map
                        if (
                            !updatedPhraseProgressItemsByCategory.has(
                                categoryId
                            )
                        ) {
                            updatedPhraseProgressItemsByCategory.set(
                                categoryId,
                                []
                            );
                        }
                        updatedPhraseProgressItemsByCategory
                            .get(categoryId)!
                            .push(updatedPhraseProgress);
                    }
                }
            }

            // Prepare data to send to API (CategoryProgressItem[]) and include updated word and phrase progress items
            const progressItemsToUpdate: CategoryProgressItem[] = [];

            // Combine word progress updates
            updatedWordProgressItemsByCategory.forEach(
                (wordProgressItems, categoryId) => {
                    const categoryInfoWithProgress =
                        updatedCategoriesMap.get(categoryId)!;
                    const existingProgressItem = progressItemsToUpdate.find(
                        pi => pi.categoryId === categoryId
                    );
                    if (existingProgressItem) {
                        existingProgressItem.wordProgressItems.push(
                            ...wordProgressItems
                        );
                    } else {
                        progressItemsToUpdate.push({
                            categoryId: categoryId,
                            lastTimePlayed:
                                categoryInfoWithProgress.categoryProgressItem
                                    .lastTimePlayed,
                            unlocked:
                                categoryInfoWithProgress.categoryProgressItem
                                    .unlocked,
                            wordProgressItems: [...wordProgressItems],
                            phraseProgressItems: []
                        });
                    }
                }
            );

            // Combine phrase progress updates
            updatedPhraseProgressItemsByCategory.forEach(
                (phraseProgressItems, categoryId) => {
                    const categoryInfoWithProgress =
                        updatedCategoriesMap.get(categoryId)!;
                    const existingProgressItem = progressItemsToUpdate.find(
                        pi => pi.categoryId === categoryId
                    );
                    if (existingProgressItem) {
                        existingProgressItem.phraseProgressItems.push(
                            ...phraseProgressItems
                        );
                    } else {
                        progressItemsToUpdate.push({
                            categoryId: categoryId,
                            lastTimePlayed:
                                categoryInfoWithProgress.categoryProgressItem
                                    .lastTimePlayed,
                            unlocked:
                                categoryInfoWithProgress.categoryProgressItem
                                    .unlocked,
                            wordProgressItems: [],
                            phraseProgressItems: [...phraseProgressItems]
                        });
                    }
                }
            );

            if (progressItemsToUpdate.length > 0) {
                try {
                    await updateWordsProgressApi(
                        studyLanguage,
                        progressItemsToUpdate
                    );

                    console.log('Review status updated in the API.');
                } catch (error) {
                    console.error(
                        'Error updating review status in the API:',
                        error
                    );
                    throw error;
                }
            }

            // Update the state immutably with all categoriesProgress
            const updatedCategoryProgressItems: CategoryInfoWithProgress[] =
                Array.from(updatedCategoriesMap.values());

            setGameProgress(prev => ({
                ...prev!,
                categoriesProgress: updatedCategoryProgressItems
            }));

            console.log('Review status updated');
        },
        [gameProgress]
    );

    const updateCategoriesProgress = async (
        studyLanguage: string,
        learnedWords: WordInfoLocalized[],
        learnedPhrases: PhraseInfoLocalized[],
        currentCategory?: CategoryInfoWithProgress
    ) => {
        if (!gameProgress) {
            console.error('GameProgress is not loaded yet.');
            return;
        }

        // Extract unique learned words and phrases to prevent processing duplicates
        const uniqueLearnedWords = Array.from(
            new Set(learnedWords.map(word => word.id))
        )
            .map(id => learnedWords.find(word => word.id === id))
            .filter((word): word is WordInfoLocalized => word !== undefined);

        const uniqueLearnedPhrases = Array.from(
            new Set(learnedPhrases.map(phrase => phrase.id))
        )
            .map(id => learnedPhrases.find(phrase => phrase.id === id))
            .filter(
                (phrase): phrase is PhraseInfoLocalized => phrase !== undefined
            );

        if (
            uniqueLearnedWords.length === 0 &&
            uniqueLearnedPhrases.length === 0
        ) {
            console.log('No unique learned words or phrases to process.');
            return;
        }

        const currentTime = new Date();

        // Create a Map for efficient access and immutability
        const updatedCategoriesMap = new Map<string, CategoryInfoWithProgress>(
            gameProgress.categoriesProgress.map(cp => [
                cp.categoryInfo.id,
                { ...cp }
            ])
        );

        // Set to track which categories have been modified
        const modifiedCategoryIds = new Set<string>();

        // Maps to store updated word and phrase progress items by category
        const updatedWordProgressItemsByCategory = new Map<
            string,
            WordProgressItem[]
        >();
        const updatedPhraseProgressItemsByCategory = new Map<
            string,
            PhraseProgressItem[]
        >();

        // Process learned words
        for (const word of uniqueLearnedWords) {
            const categoryId = word.categoryId;
            let categoryInfoWithProgress = updatedCategoriesMap.get(categoryId);

            // If the category progress does not exist, create a new CategoryInfoWithProgress
            if (!categoryInfoWithProgress) {
                const categoryInfo = categories.find(c => c.id === categoryId);
                if (!categoryInfo) {
                    console.warn(`Category with id ${categoryId} not found.`);
                    continue; // Skip if category info is missing
                }

                categoryInfoWithProgress = {
                    categoryInfo: categoryInfo,
                    categoryProgressItem: {
                        categoryId: categoryId,
                        lastTimePlayed: currentTime,
                        wordProgressItems: [],
                        phraseProgressItems: [],
                        unlocked: true
                    }
                };

                updatedCategoriesMap.set(categoryId, categoryInfoWithProgress);
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Created new category progress for categoryId: ${categoryId}`
                );
            }

            // Update last time played only for the current category
            if (
                currentCategory &&
                currentCategory.categoryInfo.id === categoryId
            ) {
                categoryInfoWithProgress.categoryProgressItem.lastTimePlayed =
                    currentTime;
                categoryInfoWithProgress.categoryProgressItem.unlocked = true;
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated lastTimePlayed for currentCategoryId: ${categoryId}`
                );
            }

            const categoryProgressItem =
                categoryInfoWithProgress.categoryProgressItem;

            // Find existing word progress
            const existingWordProgressIndex =
                categoryProgressItem.wordProgressItems.findIndex(
                    wpi => wpi.wordId === word.id
                );

            let updatedWordProgress: WordProgressItem;

            if (existingWordProgressIndex === -1) {
                // Add new word progress item
                updatedWordProgress = {
                    wordId: word.id,
                    level: gameProgress.gameLevel,
                    firstTimePlayed: currentTime,
                    lastTimePlayed: currentTime,
                    timesPlayed: 1,
                    box: 0,
                    movedToBoxAt: currentTime
                };
                categoryProgressItem.wordProgressItems = [
                    ...categoryProgressItem.wordProgressItems,
                    updatedWordProgress
                ];
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Added new WordProgressItem for wordId: ${word.id} in categoryId: ${categoryId}`
                );
            } else {
                // Update existing word progress item immutably
                const existingWordProgress =
                    categoryProgressItem.wordProgressItems[
                        existingWordProgressIndex
                    ];
                updatedWordProgress = {
                    ...existingWordProgress,
                    lastTimePlayed: currentTime,
                    timesPlayed: existingWordProgress.timesPlayed + 1
                };
                categoryProgressItem.wordProgressItems =
                    categoryProgressItem.wordProgressItems.map(wpi =>
                        wpi.wordId === word.id ? updatedWordProgress : wpi
                    );
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated WordProgressItem for wordId: ${word.id} in categoryId: ${categoryId}`
                );
            }

            // Add the updated word progress to the map
            if (!updatedWordProgressItemsByCategory.has(categoryId)) {
                updatedWordProgressItemsByCategory.set(categoryId, []);
            }
            updatedWordProgressItemsByCategory
                .get(categoryId)!
                .push(updatedWordProgress);
        }

        // Process learned phrases
        for (const phrase of uniqueLearnedPhrases) {
            const categoryId = phrase.categoryId;
            let categoryInfoWithProgress = updatedCategoriesMap.get(categoryId);

            // If the category progress does not exist, create a new CategoryInfoWithProgress
            if (!categoryInfoWithProgress) {
                const categoryInfo = categories.find(c => c.id === categoryId);
                if (!categoryInfo) {
                    console.warn(`Category with id ${categoryId} not found.`);
                    continue; // Skip if category info is missing
                }

                categoryInfoWithProgress = {
                    categoryInfo: categoryInfo,
                    categoryProgressItem: {
                        categoryId: categoryId,
                        lastTimePlayed: currentTime,
                        wordProgressItems: [],
                        phraseProgressItems: [],
                        unlocked: true
                    }
                };

                updatedCategoriesMap.set(categoryId, categoryInfoWithProgress);
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Created new category progress for categoryId: ${categoryId}`
                );
            }

            // Update last time played only for the current category
            if (
                currentCategory &&
                currentCategory.categoryInfo.id === categoryId
            ) {
                categoryInfoWithProgress.categoryProgressItem.lastTimePlayed =
                    currentTime;
                categoryInfoWithProgress.categoryProgressItem.unlocked = true;
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated lastTimePlayed for currentCategoryId: ${categoryId}`
                );
            }

            const categoryProgressItem =
                categoryInfoWithProgress.categoryProgressItem;

            // Find existing phrase progress
            const existingPhraseProgressIndex =
                categoryProgressItem.phraseProgressItems.findIndex(
                    ppi => ppi.phraseId === phrase.id
                );

            let updatedPhraseProgress: PhraseProgressItem;

            if (existingPhraseProgressIndex === -1) {
                // Add new phrase progress item
                updatedPhraseProgress = {
                    phraseId: phrase.id,
                    level: gameProgress.gameLevel,
                    firstTimePlayed: currentTime,
                    lastTimePlayed: currentTime,
                    timesPlayed: 1,
                    box: 0,
                    movedToBoxAt: currentTime
                };
                categoryProgressItem.phraseProgressItems = [
                    ...categoryProgressItem.phraseProgressItems,
                    updatedPhraseProgress
                ];
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Added new PhraseProgressItem for phraseId: ${phrase.id} in categoryId: ${categoryId}`
                );
            } else {
                // Update existing phrase progress item immutably
                const existingPhraseProgress =
                    categoryProgressItem.phraseProgressItems[
                        existingPhraseProgressIndex
                    ];
                updatedPhraseProgress = {
                    ...existingPhraseProgress,
                    lastTimePlayed: currentTime,
                    timesPlayed: existingPhraseProgress.timesPlayed + 1
                };
                categoryProgressItem.phraseProgressItems =
                    categoryProgressItem.phraseProgressItems.map(ppi =>
                        ppi.phraseId === phrase.id ? updatedPhraseProgress : ppi
                    );
                modifiedCategoryIds.add(categoryId); // Mark as modified
                console.log(
                    `Updated PhraseProgressItem for phraseId: ${phrase.id} in categoryId: ${categoryId}`
                );
            }

            // Add the updated phrase progress to the map
            if (!updatedPhraseProgressItemsByCategory.has(categoryId)) {
                updatedPhraseProgressItemsByCategory.set(categoryId, []);
            }
            updatedPhraseProgressItemsByCategory
                .get(categoryId)!
                .push(updatedPhraseProgress);
        }

        // Prepare data to send to API (CategoryProgressItem[]) and include only updated word and phrase progress items
        const progressItemsToUpdate: CategoryProgressItem[] = [];

        // Combine word and phrase progress updates
        updatedWordProgressItemsByCategory.forEach(
            (wordProgressItems, categoryId) => {
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId)!;
                const existingProgressItem = progressItemsToUpdate.find(
                    pi => pi.categoryId === categoryId
                );
                if (existingProgressItem) {
                    existingProgressItem.wordProgressItems.push(
                        ...wordProgressItems
                    );
                } else {
                    progressItemsToUpdate.push({
                        categoryId: categoryId,
                        lastTimePlayed:
                            categoryInfoWithProgress.categoryProgressItem
                                .lastTimePlayed,
                        unlocked:
                            categoryInfoWithProgress.categoryProgressItem
                                .unlocked,
                        wordProgressItems: [...wordProgressItems],
                        phraseProgressItems: []
                    });
                }
            }
        );

        updatedPhraseProgressItemsByCategory.forEach(
            (phraseProgressItems, categoryId) => {
                const categoryInfoWithProgress =
                    updatedCategoriesMap.get(categoryId)!;
                const existingProgressItem = progressItemsToUpdate.find(
                    pi => pi.categoryId === categoryId
                );
                if (existingProgressItem) {
                    existingProgressItem.phraseProgressItems.push(
                        ...phraseProgressItems
                    );
                } else {
                    progressItemsToUpdate.push({
                        categoryId: categoryId,
                        lastTimePlayed:
                            categoryInfoWithProgress.categoryProgressItem
                                .lastTimePlayed,
                        unlocked:
                            categoryInfoWithProgress.categoryProgressItem
                                .unlocked,
                        wordProgressItems: [],
                        phraseProgressItems: [...phraseProgressItems]
                    });
                }
            }
        );

        if (progressItemsToUpdate.length > 0) {
            try {
                await updateWordsProgressApi(
                    studyLanguage,
                    progressItemsToUpdate
                );
                console.log(
                    'Updated category progress successfully in the API.'
                );
            } catch (error) {
                console.error(
                    'Error updating category progress in the API:',
                    error
                );
                throw error;
            }
        } else {
            console.log('No categories to update in the API.');
        }

        // Update the state immutably with all categoriesProgress
        const updatedCategoryProgressItems: CategoryInfoWithProgress[] =
            Array.from(updatedCategoriesMap.values());

        setGameProgress(prev => ({
            ...prev!,
            categoriesProgress: updatedCategoryProgressItems
        }));

        console.log('Finish updateCategoriesProgress');
    };

    const isLockedLandscapeAvailable = (): boolean => {
        return landscapes.length > gameProgress.landscapesProgress.length;
    };

    const prepareNextLandscape = () => {
        const random = new Random();
        const shuffledLandscapes = [...landscapes].sort(
            () => random.nextDouble() - 0.5
        );

        const landscapeToReturn = shuffledLandscapes.find(
            landscape =>
                !gameProgress.landscapesProgress.some(
                    lp => lp.landscapeId === landscape.id
                )
        );

        if (landscapeToReturn) {
            // TODO: download landscape image
            setNextLandscape(landscapeToReturn);
            console.log('Next landscape: ' + landscapeToReturn.id);
        }
    };

    const unlockLandscape = async (
        studyLanguage: string,
        landscape: LandscapeInfoLocalized
    ) => {
        const newLandscapesProgressItem = {
            landscapeId: landscape.id,
            addedAt: new Date()
        };
        const newLandscapesProgress = [
            ...gameProgress.landscapesProgress,
            newLandscapesProgressItem
        ];

        try {
            await Promise.all([
                updateSelectedLandscapeIdApi(studyLanguage, landscape.id),
                updateLandscapeProgressApi(studyLanguage, 0),
                updateLandscapesProgressApi(
                    studyLanguage,
                    newLandscapesProgressItem
                )
            ]);

            setGameProgress(prev => ({
                ...prev,
                landscapeProgress: 0,
                selectedLandscapeId: landscape.id,
                landscapesProgress: newLandscapesProgress,
                landscapesUnlocked: {
                    unlocked: newLandscapesProgress.length,
                    total: landscapes.length
                }
            }));
        } catch (error) {
            console.error('Error unlocking landscape:', error);
            throw new Error('Failed to unlock landscape');
        }
    };

    const resetNextLandscape = () => {
        setNextLandscape(undefined);
    };

    const updateIsNoWordsToReviewWithSpacedRep = (
        noWordsToReviewWithSpacedRep: boolean
    ) => {
        setIsNoWordsToReviewWithSpacedRep(noWordsToReviewWithSpacedRep);
    };

    const updateIsNoPhrasesToReviewWithSpacedRep = (
        noPhrasesToReviewWithSpacedRep: boolean
    ) => {
        setIsNoPhrasesToReviewWithSpacedRep(noPhrasesToReviewWithSpacedRep);
    };

    const getWordsToReview = (studyLanguage: string) => {
        let reviewableWords: WordInfoLocalized[] = [];

        if (isNoWordsToReviewWithSpacedRep) {
            reviewableWords = getAllWordsInProgress(
                gameProgress.categoriesProgress
            );
        } else {
            reviewableWords = getAllWordsToReview(
                gameProgress.categoriesProgress
            );
        }

        // limit up to 20 words
        return getUniqueCleanWords(reviewableWords.slice(0, 20), studyLanguage);
    };

    const getPhrasesToReview = () => {
        let reviewablePhrases: PhraseInfoLocalized[] = [];

        if (isNoPhrasesToReviewWithSpacedRep) {
            reviewablePhrases = getAllPhrasesInProgress(
                gameProgress.categoriesProgress
            );
        } else {
            reviewablePhrases = getAllPhrasesToReview(
                gameProgress.categoriesProgress
            );
        }

        return reviewablePhrases.slice(0, 3);
    };

    const getWordsToReviewCount = () => {
        return getAllWordsToReviewCount(gameProgress.categoriesProgress);
    };

    const getPhrasesToReviewCount = () => {
        return getAllPhrasesToReviewCount(gameProgress.categoriesProgress);
    };

    const incrementLandscapeProgress = async (studyLanguage: string) => {
        if (gameProgress.gameLevel >= 2 && isLockedLandscapeAvailable()) {
            const updatedProgress = gameProgress.landscapeProgress + 1;
            await updateLandscapeProgressApi(studyLanguage, updatedProgress);

            setGameProgress(prev => ({
                ...prev,
                landscapeProgress: updatedProgress
            }));
        }
    };

    const goToNextGameLevel = async (studyLanguage: string) => {
        const newGameLevel = gameProgress.gameLevel + 1;
        await updateGameLevelApi(studyLanguage, newGameLevel);

        setGameProgress(prev => ({
            ...prev,
            gameLevel: newGameLevel
        }));
    };

    const claimStreakReward = async () => {
        const result = await claimStreakRewardApi();
        setGameProgress(prev => ({
            ...prev!,
            coins: result.coinsAfterUpdate
        }));
        setStreak(result.streak);
    };

    const claimFriendReferralReward = async (friendId: string) => {
        const result = await claimFriendReferralRewardApi(friendId);

        setFriends(prevFriends =>
            prevFriends.map(friend =>
                friend.friendUserId === friendId
                    ? { ...friend, canBeClaimed: false }
                    : friend
            )
        );

        setGameProgress(prev => ({
            ...prev!,
            coins: result.coinsAfterUpdate
        }));
    };

    const updateStreak = async (): Promise<Streak> => {
        const updatedStreak = await updateStreakApi();
        setStreak(updatedStreak);
        return updatedStreak;
    };

    return (
        <GameProgressContext.Provider
            value={{
                categoryToStudy,
                claimFriendReferralReward,
                claimStreakReward,
                chooseCategory,
                currentCategory,
                resetCategories,
                gameProgress,
                getWordsLearnedCount,
                getPhrasesLearnedCount,
                getWordsToReview,
                getPhrasesToReview,
                getWordsToReviewCount,
                getPhrasesToReviewCount,
                goToNextGameLevel,
                incrementLandscapeProgress,
                isLockedLandscapeAvailable,
                isNoWordsToReviewWithSpacedRep,
                isNoPhrasesToReviewWithSpacedRep,
                loadCategoryToStudy: loadCategoryToStudy,
                loadGameProgress,
                friends,
                streak,
                updateStreak,
                nextLandscape,
                prepareNextLandscape,
                resetNextLandscape,
                unlockCategory,
                unlockLandscape,
                updateReviewProgress,
                updateCategoriesProgress,
                updateSelectedLandscapeId,
                addXp,
                addCoins,
                updateCoinsState,
                updateIsNoWordsToReviewWithSpacedRep,
                updateIsNoPhrasesToReviewWithSpacedRep,
                wordsToStudy,
                phrasesToStudy,
                wordBoxWords,
                addWordToWordBox,
                claimWordBoxReward,
                resetGameProgressState
            }}
        >
            {children}
        </GameProgressContext.Provider>
    );
};

export const useGameProgress = (): GameProgressContextType => {
    const context = useContext(GameProgressContext);
    if (context === undefined) {
        throw new Error(
            'useGameProgress must be used within a GameProgressProvider'
        );
    }
    return context;
};
