import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import {
    openTelegramLink,
    retrieveLaunchParams
} from '@telegram-apps/sdk-react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { CountUp } from 'use-count-up';

import { Friend } from '@/api/types';
import { SoundName } from '@/audio/AudioManager';
import LoadingCircle from '@/components/buttons/LoadingCircle';
import TextButton from '@/components/buttons/TextButton';
import CloseIcon from '@/components/icons/CloseIcon';
import Modal from '@/components/Modal';
import {
    useGameConfig,
    useGameProgress,
    useLabels,
    useSettings
} from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { MINI_APP_URL } from '@/utils/constants';

import coinIcon from '../../assets/images/icons/coin.png';
import streakIcon from '../../assets/images/icons/streak.png';

interface FriendsModalProps {
    isVisible: boolean;
    onClose: () => void;
}

const FriendsModal: FC<FriendsModalProps> = ({ isVisible, onClose }) => {
    const { getLabel } = useLabels();
    const { vibrateLight } = useVibration();
    const { gameConfig } = useGameConfig();
    const { gameProgress, friends } = useGameProgress();
    const { settings, setIsFriendsModalViewed } = useSettings();

    const [previousCoins, setPreviousCoins] = useState(gameProgress.coins);
    const [coins, setCoins] = useState(gameProgress.coins);

    useEffect(() => {
        if (!isVisible) {
            setPreviousCoins(gameProgress.coins);
            setCoins(gameProgress.coins);
        } else {
            setCoins(gameProgress.coins);
        }
    }, [isVisible, gameProgress.coins]);

    useEffect(() => {
        if (!settings.isFriendsModalViewed && isVisible) {
            setIsFriendsModalViewed();
        }
    }, [settings.isFriendsModalViewed, isVisible]);

    const onCoinsCounterComplete = () => {
        setPreviousCoins(gameProgress.coins);
        setCoins(gameProgress.coins);
    };

    const [isClaimingReward, setIsClaimingReward] = useState(false);

    const onClaimingReward = () => {
        setIsClaimingReward(true);
    };

    const onClaimingRewardComplete = () => {
        setIsClaimingReward(false);
    };

    const onInviteClick = async () => {
        ReactGA.event({
            category: 'Friends',
            action: 'Press invite friends button'
        });

        const INVITE_URL = `${MINI_APP_URL}`;
        const launchParameters = retrieveLaunchParams();

        const referrerId = launchParameters.initData?.user?.id;
        const inviteLink = referrerId
            ? `${INVITE_URL}?startapp=referrerId${referrerId}`
            : INVITE_URL;

        const shareText = getLabel('friends.invite-text');
        const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(
            inviteLink
        )}&text=${encodeURIComponent(shareText)}`;
        openTelegramLink(fullUrl);
    };

    const onCloseClick = () => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        vibrateLight();
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} outline>
            <div className={commonStyles.modalPanel}>
                <div className="flex items-start justify-between">
                    <div>
                        <h1>{getLabel('common.friends')}</h1>
                        <p className="mb-4 mt-4 text-sm text-white">
                            {getLabel('friends.receive-coins-per-invite')}
                        </p>
                    </div>
                    <button
                        onClick={onCloseClick}
                        aria-label="Close"
                        className="mt-2 pb-3 pl-3"
                    >
                        <CloseIcon height={16} width={16} color="white" />
                    </button>
                </div>

                <div className="mb-8 flex flex-auto items-center rounded-xl bg-gray-800 bg-opacity-60 px-2 py-0.5">
                    <div className="text mr-1 flex-shrink-0 text-white">
                        <span>{getLabel('common.current-balance')}:</span>
                    </div>
                    <div className="text p-1.5 font-semibold text-white">
                        <CountUp
                            key={coins}
                            isCounting
                            start={previousCoins}
                            end={coins}
                            duration={1.5}
                            onComplete={onCoinsCounterComplete}
                        />
                    </div>
                    <div className="flex-shrink-0">
                        <img src={coinIcon} alt="Coins" className="h-6 w-6" />
                    </div>
                </div>

                <TextButton
                    className="mb-6 w-full"
                    verticalPadding="py-2"
                    horizontalPadding="px-4"
                    bgColor="bg-orange"
                    textStyle="text-white200"
                    onClick={onInviteClick}
                >
                    {getLabel('friends.invite-friends')}
                </TextButton>

                <div className="mt-2 flex flex-col gap-4">
                    <h2 className="text-xl font-semibold text-white">
                        {getLabel('friends.your-friends')}
                    </h2>

                    {/* Render Friends List */}
                    {friends?.length > 0 ? (
                        <ul className="space-y-2">
                            {friends.map(friend => (
                                <FriendListItem
                                    key={friend.friendUserId}
                                    friend={friend}
                                    reward={gameConfig.friendReferralReward}
                                    disabled={isClaimingReward}
                                    onClaimingReward={onClaimingReward}
                                    onClaimingRewardComplete={
                                        onClaimingRewardComplete
                                    }
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className="text-gray-400">
                            {getLabel('friends.no-friends-yet')}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

interface FriendListItemProps {
    friend: Friend;
    reward: number;
    disabled: boolean;
    onClaimingReward: () => void;
    onClaimingRewardComplete: () => void;
}

const FriendListItem: FC<FriendListItemProps> = ({
    friend,
    reward,
    disabled,
    onClaimingReward,
    onClaimingRewardComplete
}) => {
    const { claimFriendReferralReward } = useGameProgress();
    const { getLabel } = useLabels();

    const [handleClaimRewardState, doHandleClaimRewardUnlock] =
        useAsyncFn(async () => {
            try {
                onClaimingReward();
                await claimFriendReferralReward(friend.friendUserId);
                emitter.emit('playSound', { sound: SoundName.Coin });
                onClaimingRewardComplete();
            } catch (error) {
                console.error('Error claiming friend referral reward:', error);
                toast.error(getLabel('error.failed-to-claim-streak-reward'));
                throw error;
            }
        }, [claimFriendReferralReward]);

    const onClaimReward = async () => {
        ReactGA.event({
            category: 'Friends',
            action: 'Claim friend referral reward'
        });
        doHandleClaimRewardUnlock();
    };

    return (
        <li className="flex items-center justify-between rounded-xl bg-gray-800 p-2">
            <div className="text-white">{friend.friendUsername}</div>

            {/** If the reward can still be claimed, show the claim button. */}
            {friend.canBeClaimed ? (
                <motion.button
                    onClick={onClaimReward}
                    className={classNames(
                        'flex items-center rounded-lg bg-orange px-2 py-1',
                        disabled && 'opacity-50'
                    )}
                    whileTap={{ scale: 0.95 }}
                    initial={{ scale: 1 }}
                    disabled={disabled}
                >
                    {handleClaimRewardState.loading ? (
                        <LoadingCircle colorClassName="text-white" />
                    ) : (
                        <>
                            <span className="mr-1 font-bold text-white">
                                +{reward}
                            </span>
                            <img
                                src={coinIcon}
                                alt="Coin Icon"
                                className="h-6 w-6"
                            />
                        </>
                    )}
                </motion.button>
            ) : (
                /**
                 * If the reward is already claimed (canBeClaimed === false),
                 * show the friend's streak instead.
                 */
                <div className="flex items-center">
                    <span className="mr-2 font-bold text-orange">
                        {friend.friendStreak ?? 0}
                    </span>
                    <img
                        src={streakIcon}
                        alt={getLabel('menu.streak')}
                        className="text-red-500 -mt-1 mr-1 h-6 w-6"
                        style={{
                            filter: 'invert(27%) sepia(90%) saturate(1300%) hue-rotate(345deg) brightness(92%) contrast(90%)'
                        }}
                    />
                </div>
            )}
        </li>
    );
};

export default FriendsModal;
