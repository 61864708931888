import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isNetworkError } from 'axios-retry';

import { PanelWithText } from '@/components';
import ProgressBar from '@/components/ProgressBar';
import {
    useContent,
    useGameConfig,
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import { commonStyles } from '@/styles/commonStyles';

const MIN_LOADING_TIME_MS = 2500;

const ContentLoadingScreen = () => {
    const { navigate } = useNavigation();
    const { settings, loadSettings } = useSettings();
    const { loadGameConfig } = useGameConfig();
    const { loadContent } = useContent();
    const { loadGameProgress } = useGameProgress();
    const { getLabel } = useLabels();
    const [progressBarValue, setProgressBarValue] = useState(0);

    const [isGameConfigLoadingFinished, setIsGameConfigLoadingFinished] =
        useState(false);
    const [isSettingsLoadingFinished, setIsSettingsLoadingFinished] =
        useState(false);
    const [isContentLoadingFinished, setIsContentLoadingFinished] =
        useState(false);
    const [isGameProgressLoadingFinished, setIsGameProgressLoadingFinished] =
        useState(false);
    const [hasMinLoadingTimePassed, setHasMinLoadingTimePassed] =
        useState(false);

    useEffect(() => {
        if (
            !isContentLoadingFinished ||
            !settings?.studyLanguage ||
            isGameProgressLoadingFinished
        ) {
            return;
        }

        const loadData = async () => {
            try {
                await loadGameProgress(settings.studyLanguage, () =>
                    setIsGameProgressLoadingFinished(true)
                );
            } catch (error) {
                if (isNetworkError(error)) {
                    toast.error(getLabel('error.network-error'));
                } else {
                    console.error('Error loading game progress:', error);
                    toast.error(getLabel('error.failed-to-load-game-data'));
                }
            }
        };

        loadData();
    }, [settings?.studyLanguage, isContentLoadingFinished, loadGameProgress]);

    useEffect(() => {
        if (
            isContentLoadingFinished ||
            !isGameConfigLoadingFinished ||
            !isSettingsLoadingFinished
        )
            return;

        console.log(
            `ContentLoadingScreen. UI language: ${settings.uiLanguage}. Study language: ${settings.studyLanguage}. Language level: ${settings.languageLevel}`
        );
        if (!settings.studyLanguage || !settings.uiLanguage) return;
        loadContent(settings.uiLanguage, settings.studyLanguage, () => {
            setIsContentLoadingFinished(true);
        });
    }, [
        settings,
        loadContent,
        isGameConfigLoadingFinished,
        isContentLoadingFinished
    ]);

    useEffect(() => {
        if (isGameConfigLoadingFinished || isSettingsLoadingFinished) return;
        const loadData = async () => {
            try {
                await Promise.all([
                    loadGameConfig(() => setIsGameConfigLoadingFinished(true)),
                    loadSettings(() => setIsSettingsLoadingFinished(true))
                ]);
            } catch (error) {
                if (isNetworkError(error)) {
                    toast.error(getLabel('error.network-error'));
                } else {
                    console.error(
                        'Error loading labels or game config:',
                        error
                    );
                    toast.error(getLabel('error.failed-to-load-game-data'));
                }
            }
        };

        loadData();
    }, [
        loadGameConfig,
        loadSettings,
        isGameConfigLoadingFinished,
        isSettingsLoadingFinished
    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHasMinLoadingTimePassed(true);
        }, MIN_LOADING_TIME_MS);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const flags = [
            isContentLoadingFinished,
            isGameProgressLoadingFinished,
            hasMinLoadingTimePassed
        ];
        const allFlagsAreTrue = flags.every(flag => flag);
        if (allFlagsAreTrue) {
            setProgressBarValue(1);
        } else {
            const progress = flags.filter(flag => flag).length / flags.length;
            setProgressBarValue(progress);
        }

        if (allFlagsAreTrue) {
            setTimeout(() => {
                navigate('Hello');
            }, 500);
        }
    }, [
        isContentLoadingFinished,
        isGameProgressLoadingFinished,
        hasMinLoadingTimePassed,
        navigate
    ]);

    return (
        <div className={commonStyles.centeredContent}>
            <PanelWithText
                className={commonStyles.panelWrapper}
                text={getLabel('onboarding.loading-your-course')}
            />
            <div className="absolute bottom-20 w-52">
                <ProgressBar progressBarValue={progressBarValue} />
            </div>
        </div>
    );
};

export default ContentLoadingScreen;
