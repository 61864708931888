import {
    CategoryInfoWithProgress,
    PhraseInfoLocalized,
    WordInfoLocalized
} from '../types/category';

import { getCleanWord } from './language';

/**
 * Filters out categories that have a stamp collection bonus but are not unlocked.
 *
 * @param categoriesWithProgress - Array of categories with their progress data.
 * @returns Filtered array of categories.
 */
function filterOutLockedStampCollectionCategories(
    categoriesWithProgress: CategoryInfoWithProgress[]
): CategoryInfoWithProgress[] {
    return categoriesWithProgress.filter(
        c =>
            !c.categoryInfo.stampCollectionBonus ||
            c.categoryProgressItem.unlocked
    );
}

/**
 * Retrieves one category to study based on the current knowledge level,
 * cycling through knowledge levels if needed.
 *
 * @param categoriesWithProgress - Array of categories with their progress data.
 * @param currentKnowledgeLevel - The user's current knowledge level.
 * @returns A single CategoryInfoWithProgress or null if none found.
 */
function getCategoryToStudy(
    categoriesWithProgress: CategoryInfoWithProgress[],
    currentKnowledgeLevel: string
): CategoryInfoWithProgress | null {
    // Filter out locked stamp collection categories
    const availableCategories = filterOutLockedStampCollectionCategories(
        categoriesWithProgress
    );

    console.log(
        `getCategoryToStudy: availableCategories=${availableCategories.length}, currentKnowledgeLevel=${currentKnowledgeLevel}`
    );

    const triedLevels = new Set<string>();
    let categoryToReturn: CategoryInfoWithProgress | undefined;

    // We'll cycle through levels at most 3 times (beginner -> intermediate -> advanced -> beginner).
    while (!categoryToReturn && triedLevels.size < 3) {
        if (triedLevels.has(currentKnowledgeLevel.toLowerCase())) {
            // Already tried this level, pick the next
            currentKnowledgeLevel = getNextKnowledgeLevel(
                currentKnowledgeLevel,
                triedLevels
            );
            continue;
        }

        triedLevels.add(currentKnowledgeLevel.toLowerCase());

        // Attempt to pick a category from the current level
        categoryToReturn = pickCategory(
            availableCategories,
            currentKnowledgeLevel
        );

        // If we didn’t find anything, move to the next level
        if (!categoryToReturn) {
            currentKnowledgeLevel = getNextKnowledgeLevel(
                currentKnowledgeLevel,
                triedLevels
            );
        }
    }

    return categoryToReturn ?? null;
}

/**
 * Attempts to pick one category in the following order:
 *  0) The last played in-progress category (any level).
 *  1) An in-progress category matching the current level.
 *  2) A new category matching the current level.
 *
 * @param categories - All (unlocked) categories to choose from.
 * @param currentLevel - The user’s current knowledge level.
 * @returns A single CategoryInfoWithProgress or undefined if none found.
 */
function pickCategory(
    categories: CategoryInfoWithProgress[],
    currentLevel: string
): CategoryInfoWithProgress | undefined {
    // --- Step 0: Find the last played in-progress category across all levels ---
    const inProgressCategoriesByLastTimePlayed = categories
        .filter(
            c =>
                // Has partial progress
                (c.categoryProgressItem.wordProgressItems.length > 0 ||
                    c.categoryProgressItem.phraseProgressItems.length > 0) &&
                // Not fully completed
                (c.categoryProgressItem.wordProgressItems.length <
                    c.categoryInfo.words.length ||
                    c.categoryProgressItem.phraseProgressItems.length <
                        c.categoryInfo.phrases.length)
        )
        .sort((a, b) => {
            const aTime = a.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            const bTime = b.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            return bTime - aTime; // most recently played first
        });

    // If there's a last played category, return it right away.
    // (You can adjust if you only want the last played category *of the same level*.)
    if (inProgressCategoriesByLastTimePlayed.length > 0) {
        return inProgressCategoriesByLastTimePlayed[0];
    }

    // --- Step 1: Find an in-progress category for the current level ---
    const inProgressCategories = categories
        .filter(
            c =>
                c.categoryInfo.languageLevel.toLowerCase() ===
                    currentLevel.toLowerCase() &&
                // Has partial progress
                (c.categoryProgressItem.wordProgressItems.length > 0 ||
                    c.categoryProgressItem.phraseProgressItems.length > 0) &&
                // Not fully completed
                (c.categoryProgressItem.wordProgressItems.length <
                    c.categoryInfo.words.length ||
                    c.categoryProgressItem.phraseProgressItems.length <
                        c.categoryInfo.phrases.length)
        )
        .sort((a, b) => {
            const aTime = a.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            const bTime = b.categoryProgressItem.lastTimePlayed?.getTime() ?? 0;
            return bTime - aTime; // most recently played first
        });

    if (inProgressCategories.length > 0) {
        return inProgressCategories[0];
    }

    // --- Step 2: Find a "new" category for the current level ---
    const newCategories = findNewCategories(categories, currentLevel);
    if (newCategories.length > 0) {
        return newCategories[0];
    }

    // If nothing is found, return undefined
    return undefined;
}

/**
 * Finds new categories for the given knowledge level, preferring those that
 * are startFriendly or unlocked, then considering others if needed.
 *
 * @param categoriesWithProgress - Array of categories with progress data.
 * @param currentKnowledgeLevel - The user's current knowledge level.
 * @returns Array of new categories (could be shuffled).
 */
function findNewCategories(
    categoriesWithProgress: CategoryInfoWithProgress[],
    currentKnowledgeLevel: string
): CategoryInfoWithProgress[] {
    const random = new Random();

    // 1) Start-friendly or unlocked new categories (no word/phrase progress)
    const unlockedNew = categoriesWithProgress
        .filter(
            c =>
                c.categoryInfo.languageLevel.toLocaleLowerCase() ===
                    currentKnowledgeLevel.toLocaleLowerCase() &&
                (c.categoryInfo.startFriendly ||
                    c.categoryProgressItem.unlocked) &&
                c.categoryProgressItem.wordProgressItems.length === 0 &&
                c.categoryProgressItem.phraseProgressItems.length === 0
        )
        .sort(() => random.next() - 0.5);

    // If we have any unlocked new categories, return them
    if (unlockedNew.length > 0) {
        return unlockedNew;
    }

    // 2) Other new categories (not startFriendly/unlocked) + no progress
    const otherNew = categoriesWithProgress
        .filter(
            c =>
                c.categoryInfo.languageLevel.toLocaleLowerCase() ===
                    currentKnowledgeLevel.toLocaleLowerCase() &&
                !(
                    c.categoryInfo.startFriendly ||
                    c.categoryProgressItem.unlocked
                ) &&
                c.categoryProgressItem.wordProgressItems.length === 0 &&
                c.categoryProgressItem.phraseProgressItems.length === 0
        )
        .sort(() => random.next() - 0.5);

    return otherNew;
}

/**
 * Determines the next knowledge level in a cyclical manner.
 *
 * @param currentLevel - The current knowledge level.
 * @param triedLevels - Set of knowledge levels already attempted.
 * @returns The next knowledge level to attempt.
 */
function getNextKnowledgeLevel(
    currentLevel: string,
    triedLevels: Set<string>
): string {
    let nextLevel: string;
    switch (currentLevel.toLowerCase()) {
        case 'beginner':
            nextLevel = 'intermediate';
            break;
        case 'intermediate':
            nextLevel = 'advanced';
            break;
        case 'advanced':
            nextLevel = 'beginner';
            break;
        default:
            nextLevel = 'beginner';
            break;
    }

    // If we've already tried nextLevel, pick a different one if available
    if (triedLevels.has(nextLevel.toLowerCase())) {
        // We only have 3 levels; check if we have tried them all
        if (
            ['beginner', 'intermediate', 'advanced'].every(l =>
                triedLevels.has(l)
            )
        ) {
            // All tried; just return nextLevel (no other choices)
            return nextLevel;
        }
        // Otherwise pick the one we haven't tried
        const remaining = ['beginner', 'intermediate', 'advanced'].filter(
            l => !triedLevels.has(l)
        );
        return remaining[0] ?? nextLevel;
    }

    return nextLevel;
}

/**
 * Simple random number generator class.
 */
class Random {
    public next(): number {
        return Math.random();
    }
}

/**
 * Retrieves a list of words to study for the current category.
 *
 * @param currentCategoryWithProgress - The current category along with its progress.
 * @returns Array of WordInfoLocalized objects to study.
 */
function getWordsToStudyForCurrentCategory(
    currentCategoryWithProgress?: CategoryInfoWithProgress
): WordInfoLocalized[] {
    if (!currentCategoryWithProgress) {
        return [];
    }

    const words: WordInfoLocalized[] = [];
    const wordProgressItems =
        currentCategoryWithProgress.categoryProgressItem.wordProgressItems;

    // Sort by position ascending
    const sortedContent = currentCategoryWithProgress.categoryInfo.words.sort(
        (a, b) => a.position - b.position
    );

    // Get up to 3 unstudied words
    for (const wordInfo of sortedContent) {
        if (!wordProgressItems.some(wpi => wpi.wordId === wordInfo.id)) {
            words.push(wordInfo);
            if (words.length === 3) {
                break;
            }
        }
    }

    // If fewer than 3, fill randomly from remaining
    if (words.length < 3) {
        const remaining = sortedContent.filter(
            w => !words.some(added => added.id === w.id)
        );
        while (words.length < 3 && remaining.length > 0) {
            const randomIndex = Math.floor(Math.random() * remaining.length);
            words.push(remaining[randomIndex]);
            remaining.splice(randomIndex, 1);
        }
    }

    return words;
}

/**
 * Retrieves a list of phrases to study for the current category.
 *
 * @param currentCategoryWithProgress - The current category along with its progress.
 * @returns Array of PhraseInfoLocalized objects to study.
 */
function getPhrasesToStudyForCurrentCategory(
    currentCategoryWithProgress?: CategoryInfoWithProgress
): PhraseInfoLocalized[] {
    if (!currentCategoryWithProgress) {
        return [];
    }

    const phrases: PhraseInfoLocalized[] = [];
    const phraseProgressItems =
        currentCategoryWithProgress.categoryProgressItem.phraseProgressItems ||
        [];

    // Sort by position ascending
    const sortedContent = currentCategoryWithProgress.categoryInfo.phrases.sort(
        (a, b) => a.position - b.position
    );

    // Get up to 3 unstudied phrases
    for (const phraseInfo of sortedContent) {
        if (!phraseProgressItems.some(ppi => ppi.phraseId === phraseInfo.id)) {
            phrases.push(phraseInfo);
            if (phrases.length === 3) {
                break;
            }
        }
    }

    return phrases;
}

/**
 * Retrieves unique clean words based on the study language.
 *
 * @param words - Array of WordInfoLocalized objects.
 * @param studyLanguage - The language being studied.
 * @returns Array of unique WordInfoLocalized objects.
 */
function getUniqueCleanWords(
    words: WordInfoLocalized[],
    studyLanguage: string
): WordInfoLocalized[] {
    const uniqueWordsToPlayMap = new Map<string, WordInfoLocalized>();

    words.forEach(word => {
        const clean = getCleanWord(word.word, studyLanguage);
        if (!uniqueWordsToPlayMap.has(clean)) {
            uniqueWordsToPlayMap.set(clean, word);
        }
    });

    return Array.from(uniqueWordsToPlayMap.values());
}

export {
    filterOutLockedStampCollectionCategories,
    getCategoryToStudy,
    getPhrasesToStudyForCurrentCategory,
    getUniqueCleanWords,
    getWordsToStudyForCurrentCategory
};
