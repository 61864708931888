import { useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { isNetworkError } from 'axios-retry';

import { SoundName } from '@/audio/AudioManager';
import {
    Dialogue,
    HeaderWithCloseAndBackButton,
    LanguageLevelButton,
    TextButton
} from '@/components';
import { useLabels, useNavigation, useProfile, useSettings } from '@/context';
import emitter from '@/events/emitter';
import { useVibration } from '@/hooks/useVibration';
import { getSupportedLevelsForStudyLanguage } from '@/utils/languages';

const ChooseNewLanguageLevelScreen = () => {
    const { getLabel } = useLabels();
    const { newLanguageToStudy } = useProfile();
    const { closeProfile, navigate, navigateToProfile } = useNavigation();
    const { setStudyLanguage, setLanguageLevel, addNewStudyLanguage } =
        useSettings();
    const { vibrateLight } = useVibration();

    const supportedLevels =
        getSupportedLevelsForStudyLanguage(newLanguageToStudy);

    const [selectedLanguageLevel, setSelectedLanguageLevel] = useState<
        string | null
    >(null);
    const [isDialogueVisible, setIsDialogueVisible] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);

    const toggleLevelSelection = (languageLevel: string) => {
        if (selectedLanguageLevel === languageLevel) {
            setSelectedLanguageLevel(null); // Unselect if the same language level is selected
        } else {
            setSelectedLanguageLevel(languageLevel); // Select the new language level
        }
    };

    const onContinuePress = () => {
        if (!selectedLanguageLevel) return;
        setIsDialogueVisible(true);
    };

    const [addLanguageState, doAddLanguage] = useAsyncFn(async () => {
        try {
            // Set the study language
            await setStudyLanguage(newLanguageToStudy);

            // Add the new study language to the profile
            await addNewStudyLanguage(newLanguageToStudy);

            // Set the language level
            await setLanguageLevel(newLanguageToStudy, selectedLanguageLevel);

            // Emit success sound and vibrate
            emitter.emit('playSound', { sound: SoundName.Done });
            vibrateLight();

            setIsNavigating(true);
            navigate('Splash');
        } catch (error) {
            if (isNetworkError(error)) {
                toast.error(getLabel('error.network-error'));
            } else {
                console.error(
                    'Error adding new language:',
                    addLanguageState.error
                );
                toast.error(getLabel('change-language.language-add-failed'));
            }
        }
    }, [
        setStudyLanguage,
        addNewStudyLanguage,
        setLanguageLevel,
        newLanguageToStudy,
        selectedLanguageLevel,
        navigate,
        vibrateLight,
        emitter,
        getLabel
    ]);

    const onAddLanguage = async () => {
        if (!selectedLanguageLevel) return;
        await doAddLanguage();
    };

    const onDialogueClose = () => {
        setIsDialogueVisible(false);
    };

    const handleLanguageLevelPress = (level: string) => {
        emitter.emit('playSound', { sound: SoundName.Click2 });
        toggleLevelSelection(level);
    };

    return (
        <>
            <div>
                <HeaderWithCloseAndBackButton
                    backTitle=""
                    onBack={() => navigateToProfile('AddStudyLanguage')}
                    onClose={() => closeProfile()}
                />
                <h2>{getLabel('change-language.choose-language-level')}</h2>
                <div className="mb-6 mt-10 flex flex-col gap-6">
                    <LanguageLevelButton
                        levelName={getLabel('level.beginner')}
                        levelDescription={getLabel(
                            'onboarding.i-am-starting-from-scratch'
                        )}
                        onPress={() => handleLanguageLevelPress('beginner')}
                        isSelected={selectedLanguageLevel === 'beginner'}
                        isDisabled={!supportedLevels.includes('beginner')}
                    />
                    <LanguageLevelButton
                        levelName={getLabel('level.intermediate')}
                        levelDescription={
                            supportedLevels.includes('intermediate')
                                ? getLabel(
                                      'onboarding.i-speak-and-understand-a-little'
                                  )
                                : getLabel('common.coming-soon')
                        }
                        onPress={() => handleLanguageLevelPress('intermediate')}
                        isSelected={selectedLanguageLevel === 'intermediate'}
                        isDisabled={!supportedLevels.includes('intermediate')}
                    />
                    <LanguageLevelButton
                        levelName={getLabel('level.advanced')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => handleLanguageLevelPress('advanced')}
                        isSelected={selectedLanguageLevel === 'advanced'}
                        isDisabled={!supportedLevels.includes('advanced')}
                    />
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageLevel}
                className="w-full"
            />
            <Dialogue
                isVisible={isDialogueVisible}
                onButton1Press={onAddLanguage}
                onButton2Press={onDialogueClose}
                onClose={onDialogueClose}
                title={getLabel('change-language.add-language')}
                text={getLabel('change-language.start-new-language')}
                button1Text={getLabel('button.yes')}
                button2Text={getLabel('button.no')}
                outline
                button1Loading={addLanguageState.loading || isNavigating}
            />
        </>
    );
};

export default ChooseNewLanguageLevelScreen;
