import { type FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import LanguageLevelButton from '@/components/buttons/LanguageLevelButton';
import TextButton from '@/components/buttons/TextButton';
import BackIcon from '@/components/icons/BackIcon';
import PanelWithText from '@/components/PanelWithText';
import { useLabels, useNavigation, useSettings } from '@/context';
import { useVibration } from '@/hooks/useVibration';
import { commonStyles } from '@/styles/commonStyles';
import { getSupportedLevelsForStudyLanguage } from '@/utils/languages';

const ChooseLanguageLevelScreen: FC = () => {
    const { navigate } = useNavigation();
    const { getLabel } = useLabels();
    const { settings, setLanguageLevel } = useSettings();
    const { vibrateLight } = useVibration();

    const [selectedLanguageLevel, setSelectedLanguageLevel] = useState<
        string | null
    >(null);
    const [isNavigating, setIsNavigating] = useState(false);

    const supportedLevels = getSupportedLevelsForStudyLanguage(
        settings.studyLanguage
    );

    // Toggle selection of language level
    const toggleLevelSelection = (languageLevel: string) => {
        setSelectedLanguageLevel(prevLevel =>
            prevLevel === languageLevel ? null : languageLevel
        );
    };

    // useAsyncFn for continue button
    const [continueState, doContinue] = useAsyncFn(async () => {
        if (!selectedLanguageLevel) {
            throw new Error('No language level selected');
        }

        await setLanguageLevel(settings.studyLanguage, selectedLanguageLevel);
        setIsNavigating(true);
        navigate('ContentLoading');
    }, [
        selectedLanguageLevel,
        settings.studyLanguage,
        setLanguageLevel,
        navigate
    ]);

    // Handle errors during continue
    useEffect(() => {
        if (continueState.error) {
            toast.error(getLabel('error.failed-to-set-language-level'));
        }
    }, [continueState.error, getLabel]);

    const onContinuePress = () => {
        if (continueState.loading || isNavigating) {
            return;
        }

        doContinue();
    };

    const onBackPress = () => {
        if (continueState.loading) {
            return;
        }

        vibrateLight();
        setIsNavigating(true);
        navigate('ChooseStudyLanguage');
    };

    const isLoading = continueState.loading || isNavigating;

    return (
        <div className={commonStyles.languageSelectionScreen}>
            <button
                onClick={onBackPress}
                className="z-10 mb-8 self-start p-1"
                disabled={isLoading}
            >
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-grey bg-opacity-75 pr-1">
                    <BackIcon width={18} height={18} color="white" />
                </div>
            </button>
            <div className="mb-12 flex flex-1 flex-col justify-center">
                <PanelWithText
                    className="mb-6"
                    text={getLabel('onboarding.how-well-do-you-speak')}
                />
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.beginner')}
                        levelDescription={getLabel(
                            'onboarding.i-am-starting-from-scratch'
                        )}
                        onPress={() => {
                            if (isLoading) return;
                            toggleLevelSelection('beginner');
                        }}
                        isSelected={selectedLanguageLevel === 'beginner'}
                        isDisabled={!supportedLevels.includes('beginner')}
                    />
                </div>
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.intermediate')}
                        levelDescription={
                            supportedLevels.includes('intermediate')
                                ? getLabel(
                                      'onboarding.i-speak-and-understand-a-little'
                                  )
                                : getLabel('common.coming-soon')
                        }
                        onPress={() => {
                            if (isLoading) return;
                            toggleLevelSelection('intermediate');
                        }}
                        isSelected={selectedLanguageLevel === 'intermediate'}
                        isDisabled={!supportedLevels.includes('intermediate')}
                    />
                </div>
                <div className="mb-4">
                    <LanguageLevelButton
                        levelName={getLabel('level.advanced')}
                        levelDescription={getLabel('common.coming-soon')}
                        onPress={() => {
                            if (isLoading) return;
                            toggleLevelSelection('advanced');
                        }}
                        isSelected={selectedLanguageLevel === 'advanced'}
                        isDisabled={true} // Permanently disabled
                    />
                </div>
            </div>
            <TextButton
                onClick={onContinuePress}
                text={getLabel('button.continue')}
                isDisabled={!selectedLanguageLevel}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ChooseLanguageLevelScreen;
