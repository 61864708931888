import { useEffect, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdSearch } from 'react-icons/md';
import Fuse, { FuseResult } from 'fuse.js';
import { CountUp } from 'use-count-up';

import {
    useGameProgress,
    useLabels,
    useNavigation,
    useSettings
} from '@/context';
import useDisableVerticalSwipe from '@/hooks/useDisableVerticalSwipe';
import { getSupportedLevelsForStudyLanguage } from '@/utils/languages';

import coinIcon from '../../assets/images/icons/coin.png';
import ToggleSwitch from '../components/buttons/ToggleSwitch';
import HeaderWithCloseButton from '../components/HeaderWithCloseButton';
import { CategoryInfoWithProgress } from '../types/category';

import { CategoryCard } from './choose-category/components/CategoryCard';
import PlayUnlockedCategoryModal from './choose-category/components/PlayUnlockedCategoryModal';

const languageLevels = ['beginner', 'intermediate', 'advanced'];

const ChooseAnotherCategoryScreen: React.FC = () => {
    useDisableVerticalSwipe();
    const [isUnlockedFilterEnabled, setIsUnlockedFilterEnabled] =
        useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<
        FuseResult<CategoryInfoWithProgress>[]
    >([]);
    const { navigate } = useNavigation();
    const { gameProgress, chooseCategory } = useGameProgress();
    const { getLabel } = useLabels();
    const { settings } = useSettings();

    const [previousCoins, setPreviousCoins] = useState(gameProgress.coins);
    const [coins, setCoins] = useState(gameProgress.coins);

    // New states for level filtering
    const [selectedLevel, setSelectedLevel] = useState<string>('all');
    const supportedLevels = getSupportedLevelsForStudyLanguage(
        settings.studyLanguage
    );

    // State for showing the "Play Unlocked Category?" modal
    const [isPlayUnlockedModalVisible, setIsPlayUnlockedModalVisible] =
        useState(false);
    const [unlockedCategory, setUnlockedCategory] =
        useState<CategoryInfoWithProgress | null>(null);

    const containerRef = useRef<HTMLDivElement>(null); // Ref for scrollable container

    useEffect(() => {
        setCoins(gameProgress.coins);
    }, [gameProgress.coins]);

    // Scroll to top of the container when selectedLevel changes
    useEffect(() => {
        containerRef.current?.scrollTo(0, 0);
    }, [selectedLevel]);

    const onCoinsCounterComplete = () => {
        setPreviousCoins(gameProgress.coins);
        setCoins(gameProgress.coins);
    };

    const onChooseCategory = (
        category: CategoryInfoWithProgress,
        justUnlocked: boolean
    ) => {
        if (justUnlocked) {
            setUnlockedCategory(category);
            setIsPlayUnlockedModalVisible(true);
        } else {
            chooseCategory(category);
            if (
                category.categoryProgressItem.wordProgressItems.length ===
                category.categoryInfo.words.length
            ) {
                navigate('PhrasesIntro');
            } else {
                navigate('WordsIntro');
            }
        }
    };

    const onYesPlayUnlocked = () => {
        if (unlockedCategory) {
            chooseCategory(unlockedCategory);
            if (
                unlockedCategory.categoryProgressItem.wordProgressItems
                    .length === unlockedCategory.categoryInfo.words.length
            ) {
                navigate('PhrasesIntro');
            } else {
                navigate('WordsIntro');
            }
        }
        setIsPlayUnlockedModalVisible(false);
    };

    const onLaterPlayUnlocked = () => {
        setIsPlayUnlockedModalVisible(false);
    };

    const renderCategoryItem = ({
        item
    }: {
        item: CategoryInfoWithProgress;
    }) => (
        <CategoryCard
            category={item}
            onChooseCategory={onChooseCategory}
            insideList
        />
    );

    const onClose = () => {
        navigate('ChooseCategory');
    };

    const sortAlphabetically = (
        a: CategoryInfoWithProgress,
        b: CategoryInfoWithProgress
    ) => {
        const nameA = a.categoryInfo.name.toLowerCase();
        const nameB = b.categoryInfo.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    };

    const sortPriorityFirst = (
        a: CategoryInfoWithProgress,
        b: CategoryInfoWithProgress
    ): number => {
        const aPriority =
            a.categoryProgressItem.unlocked || a.categoryInfo.startFriendly;
        const bPriority =
            b.categoryProgressItem.unlocked || b.categoryInfo.startFriendly;
        if (aPriority && !bPriority) return -1;
        if (!aPriority && bPriority) return 1;
        return sortAlphabetically(a, b);
    };

    const isNotCompleted = (item: CategoryInfoWithProgress) => {
        return (
            item.categoryProgressItem.wordProgressItems.length <
                item.categoryInfo.words.length ||
            item.categoryProgressItem.phraseProgressItems.length <
                item.categoryInfo.phrases.length
        );
    };

    const isUnlockedIfFilterEnabled = (item: CategoryInfoWithProgress) => {
        if (!isUnlockedFilterEnabled) return true;
        return (
            item.categoryInfo.startFriendly ||
            item.categoryProgressItem.unlocked
        );
    };

    const fuseOptions = {
        keys: ['categoryInfo.name'],
        threshold: 0.3
    };

    const fuse = new Fuse(gameProgress.categoriesProgress, fuseOptions);

    useEffect(() => {
        const results = fuse.search(searchQuery);
        setSearchResults(results);
    }, [searchQuery]);

    const filterAndSort = (categories: CategoryInfoWithProgress[]) => {
        return categories.filter(
            item =>
                isNotCompleted(item) &&
                !item.categoryInfo.stampCollectionBonus &&
                isUnlockedIfFilterEnabled(item)
        );
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    // Create a cyclic order of levels starting from the current user level
    const createLevelOrder = (currentLevel: string): string[] => {
        const startIndex = languageLevels.indexOf(currentLevel.toLowerCase());
        if (startIndex === -1) return languageLevels;
        return [
            ...languageLevels.slice(startIndex),
            ...languageLevels.slice(0, startIndex)
        ];
    };

    const levelOrder = createLevelOrder(settings.languageLevel);

    // Custom sorting function following desired order
    const customSort = (
        a: CategoryInfoWithProgress,
        b: CategoryInfoWithProgress
    ): number => {
        const levelA = a.categoryInfo.languageLevel.toLowerCase();
        const levelB = b.categoryInfo.languageLevel.toLowerCase();

        // Compare based on their position in the cyclic level order
        const posA = levelOrder.indexOf(levelA);
        const posB = levelOrder.indexOf(levelB);
        if (posA !== posB) return posA - posB;

        // If levels are the same, prioritize unlocked or start-friendly topics alphabetically
        return sortPriorityFirst(a, b);
    };

    let filteredCategories: CategoryInfoWithProgress[] = [];
    const allCategories = searchQuery
        ? searchResults.map(result => result.item)
        : gameProgress.categoriesProgress;

    if (selectedLevel === 'all') {
        filteredCategories = filterAndSort(allCategories).sort(customSort);
    } else {
        filteredCategories = filterAndSort(allCategories)
            .filter(
                item =>
                    item.categoryInfo.languageLevel.toLowerCase() ===
                    selectedLevel.toLowerCase()
            )
            .sort(sortPriorityFirst);
    }

    const allTopicCompleted =
        filterAndSort(gameProgress.categoriesProgress).length === 0;

    return (
        <div className="mt-5 px-4">
            <HeaderWithCloseButton
                title={getLabel('profile.vocabulary-topics')}
                onClose={onClose}
            />

            <div className="mb-5 flex w-full items-center gap-2">
                {/* SEARCH BAR */}
                <div className="flex h-8 min-w-0 flex-grow items-center rounded-full bg-white/5 p-2">
                    <IconContext.Provider
                        value={{
                            size: '20px',
                            className: 'text-white/60 mr-2'
                        }}
                    >
                        <MdSearch />
                    </IconContext.Provider>
                    <input
                        type="text"
                        placeholder={getLabel(
                            'common.search-vocabulary-topics'
                        )}
                        value={searchQuery}
                        onChange={handleInputChange}
                        className="min-w-0 flex-1 bg-transparent text-white focus:outline-none"
                    />
                </div>

                {/* COINS DISPLAY */}
                <div className="flex h-8 flex-shrink-0 items-center rounded-3xl bg-white/5">
                    <img src={coinIcon} alt="Coin Icon" className="h-8 w-8" />
                    <span className="text-md mx-2 whitespace-nowrap font-bold text-white">
                        <CountUp
                            key={coins}
                            isCounting
                            start={previousCoins}
                            end={coins}
                            duration={1.5}
                            onComplete={onCoinsCounterComplete}
                        />
                    </span>
                </div>
            </div>

            {/* Only Unlocked Toggle */}
            <div className="mb-5 flex items-center justify-end gap-4">
                <span className="text-white">
                    {getLabel('category.unlocked')}
                </span>
                <ToggleSwitch
                    isEnabled={isUnlockedFilterEnabled}
                    setIsEnabled={setIsUnlockedFilterEnabled}
                />
            </div>

            {/* Level Filter Buttons */}
            <div className="mb-5 flex gap-4 overflow-x-auto">
                {/* "All" Button */}
                <button
                    onClick={() => setSelectedLevel('all')}
                    className={`rounded-full px-3 py-1 text-sm font-semibold text-white focus:outline-none ${
                        selectedLevel === 'all'
                            ? 'bg-white/10 outline-1 outline-white'
                            : 'text-blue-100 hover:bg-white/5 hover:text-white'
                    }`}
                >
                    {getLabel('common.all-levels')}
                </button>
                {/* Buttons for each supported level */}
                {supportedLevels.map(level => (
                    <button
                        key={level}
                        onClick={() => setSelectedLevel(level)}
                        className={`rounded-full px-3 py-1 text-sm font-semibold text-white focus:outline-none ${
                            selectedLevel === level
                                ? 'bg-white/10 outline-1 outline-white'
                                : 'text-blue-100 hover:bg-white/5 hover:text-white'
                        }`}
                    >
                        {getLabel(`level.${level.toLowerCase()}`)}
                    </button>
                ))}
            </div>

            {allTopicCompleted && (
                <span className="mb-5 text-white">
                    {getLabel('categories.all-completed')}
                </span>
            )}

            {!allTopicCompleted && (
                <div
                    ref={containerRef}
                    className="grid h-[calc(100vh-200px)] w-full auto-rows-max grid-cols-3 gap-6 overflow-y-scroll pb-16"
                >
                    {filteredCategories.map(item => (
                        <div key={item.categoryInfo.id} className="w-full">
                            {renderCategoryItem({ item })}
                        </div>
                    ))}
                </div>
            )}

            <PlayUnlockedCategoryModal
                isVisible={isPlayUnlockedModalVisible}
                onYesPress={onYesPlayUnlocked}
                onLaterPress={onLaterPlayUnlocked}
                onClose={onLaterPlayUnlocked}
            />
        </div>
    );
};

export default ChooseAnotherCategoryScreen;
